import React, { useRef, useState, useEffect, useCallback } from 'react';
import { FilterOptionsState, DialogTitle } from '@mui/material';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import omitProps from 'lodash/omit';

import infoIcon from '../../assets/images/info_icon_ads_color.svg';

import ModalShell from '../ModalShell';
import Input from '../Form/Input';
import handleErrors from '../../helpers/HandleErrors';
import FormContainer from '../Form/FormContainer/FormContainer';
import {
  NewSimulation,
  EditSimulation
} from '../../store/modules/Simulation/Simulation.actions';
import {
  SimulationProps,
  SimulationPropsData
} from '../../store/modules/Simulation/Simulation.types';
import paths from '../../routes/paths';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { isUUID, getUuid } from '../../helpers/UUID';
import simualtionLocalStorage from './Simulation.LocalStorage';
import { attributeMaskOrUnmask } from '../../helpers/Utils';
import classesGTM from '../../config';
import {
  SaveSimulation,
  UpdateSimulation
} from '../../store/modules/Simulations/Simulations.actions';
import { Roles } from '../../store/modules/Users/Users.types';
import AutocompleteInput from '../Form/AutocompleteInput';
import { Agency } from '../../store/modules/Agencies/Agencies.types';
import { LoadAgenciesRequest } from '../../store/modules/Agencies/Agencies.actions';
import Information from '../Information';
import InformationWithoutIcon from '../Information/InformationWithoutIcon';
import { Client } from '../../store/modules/Clients/Clients.types';
import LoadClientsRequest from '../../store/modules/Clients/Clients.actions';
import CheckboxInput from '../Form/CheckboxInput';
import {
  LoadCommitmentsRequest,
  SaveCommitment
} from '../../store/modules/Commitments/Commitments.actions';
import { LoadTermsRequest } from '../../store/modules/Terms/Terms.actions';
import { Term } from '../../store/modules/Terms/Terms.types';
import { LoadSTRequest } from '../../store/modules/SimulationsType/SimulationsType.actions';
import {
  EnumSimulationsTypes,
  ISimulationsTypes
} from '../../store/modules/SimulationsType/SimulationsType.types';
import simulationsValidator from '../../validations/simulations.validations';

export interface ModalSimulationData {
  name: string;
}

interface Props {
  dataModal?: SimulationProps;
  onClickCancel?(): void;
  onClickInitSimulation?(type?: number): void;
  open: boolean;
}

const ModalSimulation: React.FC<Props> = ({
  onClickCancel,
  onClickInitSimulation,
  open = false,
  dataModal
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formRef = useRef<FormHandles>(null);
  const simulationStore = simualtionLocalStorage();
  const {
    simulation,
    auth: { profile },
    agencies: currentAgencies,
    term,
    commitments: currentCommitments,
    simulationType,
    clients: {
      data: { uniques: uniquesClients, all: currentClients } = {
        uniques: [],
        all: []
      },
      ...rest
    }
  } = useAppSelector((state) => state);
  const [focusedField, setFocusedField] = useState('');
  const [availableCnpjs, setAvailableCnpjs] = useState<Client[] | undefined>(
    []
  );
  const [agencySelected, setAgencySelected] = useState<Agency | undefined>(
    undefined
  );
  const [oiClientSelected, setOiClientSelected] = useState<number | undefined>(
    undefined
  );
  const [cnjpSelected, setCnpjSelected] = useState<Client | undefined>(
    undefined
  );
  const [clientSelected, setClientSelected] = useState<
    (Client & { fromDataModal?: boolean }) | undefined
  >(undefined);

  const [currentSimulationType, setCurrentSimulationType] = useState<
    ISimulationsTypes | undefined
  >(undefined);

  const [isDigital, setIsDigital] = useState<boolean>(false);
  const [agencyKey, setAgencyKey] = useState(0);
  const [clientKey, setClientKey] = useState(0);
  const [cnpjKey, setCnpjKey] = useState(0);
  const [commitment, setCommitment] = useState<Term | undefined>(undefined);
  const [commitmentChecked, setCommitmentChecked] = useState(false);
  const [checkError, setCheckError] = useState('');

  useEffect(() => {
    if (
      (currentAgencies?.error && currentAgencies?.message !== '') ||
      (rest?.error && rest?.message !== '')
    ) {
      toast.error(
        currentAgencies?.error ? currentAgencies?.message : rest?.message
      );
    }
  }, [
    currentAgencies?.error,
    currentAgencies?.message,
    rest?.error,
    rest?.message
  ]);

  useEffect(() => {
    if (profile?.role === Roles?.AGENCY) {
      dispatch(LoadTermsRequest());
    }
  }, [profile?.role, dispatch]);

  useEffect(() => {
    if (term?.data?.length) {
      setCommitment(term?.data[0]);
    }

    if (term?.error && term?.message !== '') {
      toast.error(term?.message);
      setCommitment(undefined);
      setCommitmentChecked(false);
      setCheckError(term?.message);
    }
  }, [term]);

  useEffect(() => {
    if (!currentAgencies?.error && profile?.role === Roles?.AGENCY) {
      dispatch(LoadAgenciesRequest());
    }

    if (profile?.role !== Roles?.AGENCY) {
      dispatch(LoadSTRequest());
    }
  }, [profile?.role, dispatch, currentAgencies?.error]);

  useEffect(() => {
    if (profile?.role === Roles?.AGENCY) {
      const check = currentCommitments.data.some(
        (x) =>
          x.agency === agencySelected?.me_agencia &&
          x.email === profile?.email &&
          x.cnpj === agencySelected?.nu_cgc_cpf &&
          x.filial === agencySelected?.me_filial_agencia &&
          x.termId === commitment?.id
      );

      setCommitmentChecked(check);
    }
  }, [
    agencySelected,
    currentCommitments.data,
    profile?.role,
    commitment?.id,
    profile?.email
  ]);

  useEffect(() => {
    if (
      !currentCommitments?.data?.length &&
      !(currentCommitments?.message !== '') &&
      !currentCommitments?.loading &&
      profile?.role === Roles?.AGENCY
    ) {
      dispatch(LoadCommitmentsRequest());
    }
  }, [currentCommitments, profile?.role, dispatch]);

  useEffect(() => {
    if (agencySelected) {
      dispatch(LoadClientsRequest(agencySelected.nu_cgc_cpf));
    }
  }, [agencySelected, dispatch]);

  useEffect(() => {
    if (dataModal?.nu_cgc_cpf_agencia && !agencySelected) {
      const agency = currentAgencies?.data?.find(
        (ag) => ag?.nu_cgc_cpf === dataModal?.nu_cgc_cpf_agencia
      );

      setAgencySelected(agency);
    }
  }, [dataModal, currentAgencies, agencySelected]);

  useEffect(() => {
    if (currentAgencies?.data?.length === 1) {
      setAgencySelected(currentAgencies?.data[0]);
      setAgencyKey((prev) => prev + 1);
    }
  }, [currentAgencies]);

  useEffect(() => {
    if (uniquesClients?.length === 1) {
      setClientSelected(uniquesClients[0]);
      setOiClientSelected(uniquesClients[0]?.oi_cliente);
      setClientKey((prev) => prev + 1);
    }
  }, [uniquesClients]);

  useEffect(() => {
    if (open && !dataModal) {
      if (currentAgencies?.data?.length === 1) {
        setAgencySelected(currentAgencies?.data[0]);
        setAgencyKey((prev) => prev + 1);
      }

      if (uniquesClients?.length === 1) {
        setClientSelected(uniquesClients[0]);
        setOiClientSelected(uniquesClients[0]?.oi_cliente);
        setClientKey((prev) => prev + 1);
      }

      if (currentClients?.length === 1) {
        setCnpjSelected(currentClients[0]);
        setCnpjKey((prev) => prev + 1);
      }
    }
  }, [open, dataModal, currentAgencies, uniquesClients, currentClients]);

  useEffect(() => {
    if (dataModal?.oi_cliente) {
      const client = currentClients?.find(
        (cl) => cl?.oi_cliente === Number(dataModal?.oi_cliente)
      );

      if (!client) return;

      setClientSelected({
        ...client,
        fromDataModal: true
      });
    }
  }, [dataModal, currentClients]);

  useEffect(() => {
    if (dataModal?.cpf_cnpj_cliente && clientSelected?.fromDataModal) {
      const cnpj = currentClients?.find(
        (cl) => cl?.cpf_cnpj === dataModal?.cpf_cnpj_cliente
      );

      setCnpjSelected(cnpj);
    }
  }, [dataModal, currentClients, clientSelected]);

  useEffect(() => {
    if (oiClientSelected || clientSelected?.oi_cliente) {
      const cnpjSelectedClient = currentClients?.filter(
        (client) =>
          client?.oi_cliente === oiClientSelected ||
          client?.oi_cliente === clientSelected?.oi_cliente
      );
      setAvailableCnpjs(cnpjSelectedClient);

      if (cnpjSelectedClient?.length === 1) {
        setCnpjSelected(cnpjSelectedClient[0]);
        setCnpjKey((prev) => prev + 1);
      }
    }
  }, [oiClientSelected, clientSelected, currentClients]);

  const handleSubmit = async (formData: SimulationPropsData) => {
    const schema = simulationsValidator(profile, !!simulation.id);

    try {
      formRef.current?.setErrors({});

      await schema.validate(formData, {
        abortEarly: false
      });

      if (!commitmentChecked && profile?.role === Roles?.AGENCY) {
        setCheckError(
          'É preciso aceitar as regras acima para o acesso à plataforma.'
        );

        if (currentCommitments?.error && profile?.role === Roles?.AGENCY) {
          setCheckError(
            'Não foi possível salvar o seu aceite ao Termo de Uso e Confiabilidade de Dados. Tente novamente mais tarde.'
          );
        }
        return;
      }

      const data = {
        ...dataModal,
        ...formData,
        quantityScenarios: simulation?.scenarios?.length
      };

      if (isUUID(data.uuid)) {
        dispatch(
          EditSimulation({
            name: data.name,
            me_filial_agencia: data?.agency?.me_filial_agencia,
            nu_cgc_cpf_agencia: data?.agency?.nu_cgc_cpf,
            oi_agencia: data?.agency?.oi_agencia,
            cpf_cnpj_cliente: data?.cnpj?.cpf_cnpj,
            me_cliente: data?.cnpj?.me_cliente,
            oi_cliente: data?.client?.oi_cliente,
            currentScenery: data?.currentScenery,
            currentSceneryId: data?.currentSceneryId,
            scenarios: data?.scenarios
          })
        );

        simulationStore.set(
          omitProps(data, ['scenarios', 'currentSceneryId', 'currentScenery'])
        );

        if (data?.id) {
          if (data?.simulationTypeId === EnumSimulationsTypes.LINEAR) {
            dispatch(
              UpdateSimulation(
                {
                  ...data,

                  name: data.name,
                  me_filial_agencia: data?.agency?.me_filial_agencia,
                  nu_cgc_cpf_agencia: data?.agency?.nu_cgc_cpf,
                  oi_agencia: data?.agency?.oi_agencia,
                  cpf_cnpj_cliente: data?.cnpj?.cpf_cnpj,
                  me_cliente: data?.cnpj?.me_cliente,
                  oi_cliente: data?.client?.oi_cliente,
                  scenarios: data?.scenarios
                },
                data?.id
              )
            );
          } else {
            dispatch(
              UpdateSimulation(
                {
                  ...data,

                  name: data.name,
                  me_filial_agencia: data?.agency?.me_filial_agencia,
                  nu_cgc_cpf_agencia: data?.agency?.nu_cgc_cpf,
                  oi_agencia: data?.agency?.oi_agencia,
                  cpf_cnpj_cliente: data?.cnpj?.cpf_cnpj,
                  me_cliente: data?.cnpj?.me_cliente,
                  oi_cliente: data?.client?.oi_cliente,
                  groups: data?.groups
                },
                data?.id,
                false,
                true
              )
            );
          }
        }

        if (onClickInitSimulation) onClickInitSimulation();
        return;
      }

      const newSimulation = {
        uuid: getUuid(),
        name: data.name,
        me_filial_agencia: data?.agency?.me_filial_agencia,
        nu_cgc_cpf_agencia: data?.agency?.nu_cgc_cpf,
        oi_agencia: data?.agency?.oi_agencia,
        cpf_cnpj_cliente: data?.cnpj?.cpf_cnpj,
        me_cliente: data?.cnpj?.me_cliente,
        oi_cliente: data?.client?.oi_cliente,
        simulation_type_id: data?.st?.id
      };

      simulationStore.set(newSimulation);

      dispatch(NewSimulation(newSimulation));

      dispatch(SaveSimulation({ ...data, ...newSimulation }, false, isDigital));

      if (onClickInitSimulation)
        onClickInitSimulation(currentSimulationType?.id);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = handleErrors(error);
        formRef.current?.setErrors(errors);
        if (!commitmentChecked && profile?.role === Roles?.AGENCY) {
          setCheckError(
            'É preciso aceitar as regras acima para o acesso à plataforma.'
          );
        }
        return;
      }
    }
  };

  const filterAgencies = (
    options: Agency[],
    { inputValue }: FilterOptionsState<any>
  ) => {
    return options.filter((option) =>
      option?.nome_agencia?.toLowerCase()?.includes(inputValue?.toLowerCase())
    );
  };

  const filterClients = (
    options: Client[],
    { inputValue }: FilterOptionsState<any>
  ) => {
    return options.filter((option) =>
      option?.nome_cliente?.toLowerCase()?.includes(inputValue?.toLowerCase())
    );
  };

  const filterCnpjs = (
    options: Client[],
    { inputValue }: FilterOptionsState<any>
  ) => {
    return options.filter((option) =>
      option?.cpf_cnpj?.toLowerCase()?.includes(inputValue?.toLowerCase())
    );
  };

  const maskCpfCnpj = (cpfOrCnpj: string) => {
    if (!cpfOrCnpj) return '';

    if (cpfOrCnpj?.length === 11) {
      const cpfWithMask = cpfOrCnpj?.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        '$1.$2.$3-$4'
      );
      return cpfWithMask;
    }

    const cnpjWithMask = cpfOrCnpj?.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );

    return cnpjWithMask;
  };

  const handleClickOk = async () => {
    if (formRef?.current) {
      try {
        if (
          profile?.role === Roles?.AGENCY &&
          commitmentChecked &&
          agencySelected &&
          !currentCommitments.data
            .map((x) => x.agency)
            .some((x) => x === agencySelected?.me_agencia)
        ) {
          if (!commitment) return;

          // Não remova o await, apesar do TS reclamar, só assim ele aguarda a execução da função.
          await dispatch(
            SaveCommitment({
              message: commitment?.term,
              agency: agencySelected?.me_agencia,
              email: profile?.email,
              cnpj: agencySelected?.nu_cgc_cpf,
              filial: agencySelected?.me_filial_agencia,
              termId: commitment?.id
            })
          );
        }

        formRef.current.submitForm();
      } catch (error) {
        toast.error(
          currentCommitments?.error
            ? 'Não foi possível salvar o seu aceite ao Termo de Uso e Confiabilidade de Dados. Tente novamente mais tarde.'
            : 'Não foi possível iniciar o planejamento. Tente novamente mais tarde.'
        );
      }
    }
  };

  const handleClickCancel = () => {
    if (!dataModal?.uuid) navigate(paths.home.childrens.simulations);
    setCheckError('');
    setAvailableCnpjs(undefined);
    setAgencySelected(undefined);
    setFocusedField('');

    if (onClickCancel) onClickCancel();
  };

  const handleAgencyChange = (value: Agency | undefined) => {
    setAgencySelected(value);
    setClientSelected(undefined);
    setOiClientSelected(undefined);

    if (value) {
      window.dataLayer.push({
        event: 'agencyChange',
        simulation: {
          id: simulation?.id,
          agency: {
            mnemonic: value?.me_filial_agencia,
            oi: value?.oi_agencia,
            cnpj: value?.nu_cgc_cpf,
            name: value?.nome_agencia
          }
        }
      });
    }

    if (profile?.role === Roles?.AGENCY) {
      const check = currentCommitments.data.some(
        (x) =>
          x.agency === agencySelected?.me_agencia &&
          x.email === profile?.email &&
          x.cnpj === agencySelected?.nu_cgc_cpf &&
          x.filial === agencySelected?.me_filial_agencia &&
          x.termId === commitment?.id
      );
      setCommitmentChecked(check);
    }
  };

  const handleClientChange = (value: Client | undefined) => {
    if (value?.oi_cliente === oiClientSelected) {
      return;
    }
    setAvailableCnpjs(undefined);
    setOiClientSelected(value?.oi_cliente);

    if (value) {
      window.dataLayer.push({
        event: 'clientChange',
        simulation: {
          id: simulation?.id,
          client: {
            mnemonic: value?.me_cliente,
            oi: value?.oi_cliente,
            cnpj: value?.cpf_cnpj,
            name: value?.nome_cliente
          }
        }
      });
    }
  };

  const handleCnpjChange = (value: Client | undefined) => {
    setCnpjSelected(value);
    setClientSelected(value);

    if (value) {
      window.dataLayer.push({
        event: 'cnpjChange',
        simulation: {
          id: simulation?.id,
          cnpj: {
            mnemonic: value?.me_cliente,
            oi: value?.oi_cliente,
            cnpj: value?.cpf_cnpj,
            name: value?.nome_cliente
          }
        }
      });
    }
  };

  const handleSTChange = (value: ISimulationsTypes | undefined) => {
    setCurrentSimulationType(value);
    setIsDigital(value?.id === EnumSimulationsTypes.DIGITAL);

    if (value) {
      window.dataLayer.push({
        event: 'simulationTypeChange',
        simulation: {
          id: simulation?.id,
          simulation_type: {
            id: value?.id,
            type: value?.type
          }
        }
      });
    }
  };

  const handleClearError = (names: string | string[]) => {
    if (formRef.current) {
      if (Array.isArray(names)) {
        names.forEach((name) => {
          formRef.current?.setFieldError(name, '');
        });
      } else {
        formRef.current?.setFieldError(names, '');
      }
    }
  };

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommitmentChecked(event.target.checked);
    if (checkError) setCheckError('');
  };

  const getTagManagerClasses = useCallback(() => {
    if (!dataModal?.uuid) {
      if (isDigital) return classesGTM.home.newSimulationDigital;
      return classesGTM.home.initSimulation;
    }
    return classesGTM.simulation.update;
  }, [dataModal?.uuid, isDigital]);

  return (
    <ModalShell
      classTagManagerButtons={{
        btnOk: getTagManagerClasses()
      }}
      labelBtnOK={dataModal?.uuid ? 'atualizar' : 'continuar'}
      onClickCancel={handleClickCancel}
      onClickOK={handleClickOk}
      noClose
      open={open}
      title={dataModal?.uuid ? 'Atualizar Planejamento' : 'Novo planejamento'}
    >
      <FormContainer
        data-testid="form"
        formRef={formRef}
        fullWidth
        initialData={dataModal}
        onSubmit={handleSubmit}
      >
        <InformationWithoutIcon
          message="Para começar, preencha os campos a seguir:"
          icon={infoIcon}
        />
        <div className="form-divisor d-flex flex-column">
          <div className="input-wrapper">
            <div className="input-wrapper__divisor__column">
              <Input
                autoComplete="name"
                className="mt-2"
                data-testid="name"
                inputProps={{ maxLength: 50 }}
                onFocus={() => setFocusedField('name')}
                label={`Título do plano${
                  !dataModal?.name && focusedField !== 'name' ? '*' : ''
                }`}
                name="name"
                placeholder=" "
                InputLabelProps={{
                  required: !(!dataModal?.name && focusedField !== 'name')
                }}
                required
                {...attributeMaskOrUnmask(true)}
              />
            </div>
          </div>
          {profile?.role !== Roles?.AGENCY && !simulation.id && (
            <div className="input-wrapper">
              <div className="input-wrapper__divisor__column">
                <AutocompleteInput
                  data-testid="st"
                  dataClarityMask
                  autoHighlight
                  disabled={simulationType.loading}
                  onFocus={() => setFocusedField('st')}
                  // filterOptions={filterAgencies}
                  label={`Plataforma${
                    !currentSimulationType?.type && focusedField !== 'st'
                      ? '*'
                      : ''
                  }`}
                  labelProp="name"
                  loading={simulationType.loading}
                  name="st"
                  defaultValue={{
                    ...currentSimulationType,
                    name: currentSimulationType?.type
                  }}
                  onChange={handleSTChange}
                  onClearError={handleClearError}
                  options={simulationType.data?.map((st) => ({
                    ...st,
                    name: st.type
                  }))}
                  required
                  returnObject
                  valueProp="type"
                  inputLabelProps={{
                    required: !(
                      !currentSimulationType?.type && focusedField !== 'st'
                    )
                  }}
                />
              </div>
            </div>
          )}
          {profile?.role === Roles?.AGENCY && (
            <>
              <div className="input-wrapper">
                <div className="input-wrapper__divisor_select">
                  <div className="input-wrapper__divisor__column">
                    <AutocompleteInput
                      key={agencyKey}
                      data-testid="agency"
                      dataClarityMask
                      autoHighlight
                      disableClearable
                      disabled={
                        currentAgencies.loading || Boolean(dataModal?.uuid)
                      }
                      onFocus={() => setFocusedField('agency')}
                      filterOptions={filterAgencies}
                      label={`Agência${
                        !agencySelected?.nome_agencia &&
                        focusedField !== 'agency'
                          ? '*'
                          : ''
                      }`}
                      labelProp="name"
                      loading={currentAgencies.loading}
                      name="agency"
                      defaultValue={{
                        ...agencySelected,
                        name: agencySelected?.nome_agencia
                      }}
                      onChange={handleAgencyChange}
                      onClearError={handleClearError}
                      options={currentAgencies.data?.map((agency) => ({
                        ...agency,
                        name: agency.nome_agencia
                      }))}
                      required
                      returnObject
                      valueProp="nome_agencia"
                      inputLabelProps={{
                        required: !(
                          !agencySelected?.nome_agencia &&
                          focusedField !== 'agency'
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-wrapper__divisor_select">
                  <div className="input-wrapper__divisor__column">
                    <AutocompleteInput
                      key={clientKey}
                      data-testid="client"
                      dataClarityMask
                      autoHighlight
                      disabled={
                        !agencySelected ||
                        !uniquesClients?.length ||
                        rest?.loading
                      }
                      filterOptions={filterClients}
                      onFocus={() => setFocusedField('client')}
                      label="Anunciante"
                      labelProp="name"
                      loading={rest.loading}
                      name="client"
                      defaultValue={{
                        ...clientSelected,
                        name: clientSelected?.nome_cliente
                      }}
                      onChange={handleClientChange}
                      onClearError={handleClearError}
                      options={
                        uniquesClients?.map((client) => ({
                          ...client,
                          name: client.nome_cliente
                        })) ?? []
                      }
                      returnObject
                      valueProp="nome_cliente"
                    />
                  </div>
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-wrapper__divisor_select">
                  <div className="input-wrapper__divisor__column">
                    <AutocompleteInput
                      key={cnpjKey}
                      data-testid="cnpj"
                      dataClarityMask
                      autoHighlight
                      disabled={!availableCnpjs?.length || rest?.loading}
                      defaultValue={
                        cnjpSelected
                          ? {
                              ...cnjpSelected,
                              name: `${maskCpfCnpj(cnjpSelected?.cpf_cnpj)} - ${
                                cnjpSelected.razao_social
                              }`
                            }
                          : undefined
                      }
                      filterOptions={filterCnpjs}
                      onFocus={() => setFocusedField('cnpj')}
                      label="CNPJ"
                      labelProp="name"
                      loading={rest?.loading}
                      name="cnpj"
                      onClearError={handleClearError}
                      onChange={handleCnpjChange}
                      options={
                        availableCnpjs?.map((client) => ({
                          ...client,
                          name: `${maskCpfCnpj(client?.cpf_cnpj)} - ${
                            client.razao_social
                          }`
                        })) ?? []
                      }
                      returnObject
                      valueProp="cpf_cnpj"
                    />
                  </div>
                </div>
              </div>
              <Information
                message="Para exportar o Mapa para Compra é necessário informar o anunciante e o CNPJ."
                icon={infoIcon}
              />
            </>
          )}
        </div>
        {profile?.role === Roles?.AGENCY ? (
          <div>
            <DialogTitle className="modal-container__title no-padding-left">
              Termo de Uso e Confiabilidade de Dados
            </DialogTitle>
            <div
              dangerouslySetInnerHTML={{ __html: commitment?.term ?? '' }} // eslint-disable-line react/no-danger
              className="scrollable-text"
            />
            <div className="spacing" />
            <CheckboxInput
              checked={commitmentChecked}
              label="Li, aceito e concordo com as regras acima como condição de acesso à plataforma."
              onChange={handleChecked}
              disabled={term.loading || !commitment || Boolean(dataModal?.uuid)}
              size="small"
              error={!!checkError}
            />
            {checkError && <InformationWithoutIcon message={checkError} />}
          </div>
        ) : null}
      </FormContainer>
    </ModalShell>
  );
};

ModalSimulation.defaultProps = {
  dataModal: undefined,
  onClickCancel: () => undefined,
  onClickInitSimulation: () => undefined
};

export default ModalSimulation;
