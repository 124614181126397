import './HorizontalBarChart.scss';
import React from 'react';
import { ResponsiveBar, BarTooltipProps } from '@nivo/bar';
import { HorizontalBarChartData } from './types';

interface Props {
  data: HorizontalBarChartData[];
  padding?: number;
  height?: string;
  valueFormat?: (value: number) => string;
}
const maxPadding = 1;

const customTooltip =
  (): React.FC<BarTooltipProps<HorizontalBarChartData>> => (datum) => {
    const { data } = datum;

    return (
      <div className="collumn-chart__tooltip">
        <div className="collumn-chart__tooltip__title">{data.id}</div>
        <div className="collumn-chart__tooltip__text">{data.label}</div>
      </div>
    );
  };

const calcPaddingBars = (quantityBars: number, padding: number) => {
  const res = maxPadding - quantityBars * padding - 0.05;

  return res <= 0 ? 0.1 : res;
};

export default function HorizontalBarChart({
  data,
  padding,
  height,
  valueFormat
}: Props) {
  return (
    <div style={{ position: 'relative', height: `${height}` }}>
      <ResponsiveBar
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          legend: '',
          legendPosition: 'middle',
          legendOffset: 32,
          tickValues: 8,
          format: (value) => {
            const barData = data.find((d) => d.id === value);
            if (barData) {
              const idText = barData.id;
              return idText;
            }
            return value;
          }
        }}
        axisTop={null}
        layout="horizontal"
        colors={{ datum: 'data.color' }}
        data={[...data].reverse()}
        enableLabel={false}
        indexBy="id"
        keys={['value']}
        gridXValues={8}
        axisBottom={null}
        indexScale={{ type: 'band', round: true }}
        margin={{ bottom: 80, left: 150, right: 40, top: 30 }}
        padding={calcPaddingBars(data.length, padding ?? 0.1)}
        role="application"
        tooltip={customTooltip()}
        valueScale={{ type: 'linear', nice: true }}
        enableGridX
        enableGridY={false}
        isInteractive
        enableTotals
        valueFormat={(value) =>
          valueFormat ? valueFormat(value) : String(value)
        }
        theme={{
          labels: {
            text: {
              fontSize: 14,
              fontFamily: 'inherit',
              fontWeight: 400,
              fill: '#000000'
            }
          },
          grid: {
            line: {
              stroke: '#e0e0e0',
              strokeDasharray: '4 4'
            }
          }
        }}
      />
    </div>
  );
}

HorizontalBarChart.defaultProps = {
  padding: 0.1,
  height: 600,
  valueFormat: undefined
};
