import { PVIPlanningData } from '../../components/Planning/usePlanningGAdsPvi';
import { apiV1 } from '../shared/Api.service';
import { IDigitalSimulationExport } from './Types/Export/ExportDigital.types';

const path = '/export';
const digitalPath = '/digital/export';

export async function postExportDigitalSimulation(data: {
  simulation: IDigitalSimulationExport;
}) {
  const response = await apiV1.post(digitalPath, data.simulation, {
    responseType: 'blob'
  });
  return response;
}

export default async function postExport(data: {
  pvi: PVIPlanningData[] | undefined;
  title: string;
  agencyUf: string | undefined;
}) {
  const { data: response } = await apiV1.post(path, data);
  return response;
}
