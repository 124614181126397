import './Scenarios.scss';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../hooks';
import {
  AddScenery,
  RemoveScenery,
  SetCurrentScenery
} from '../../store/modules/Simulation/Simulation.actions';
import scenariosLocalStorage, {
  ScenariosStore
} from './Scenarios.LocalStorage';
import paths from '../../routes/paths';
import StyledTabs, { StyledTab } from '../StyledTabs';
import ModalScenarios from './ModalScenarios';
import {
  SimulationScenery,
  SimulationDataScenery
} from '../../store/modules/Simulation/Simulation.types';
import { RemoveProgramsScenery } from '../../store/modules/Programs/Programs.actions';
import { RemoveFiltersScenery } from '../../store/modules/Filters/Filters.actions';
import { RemovePlanningProgramsScenery } from '../../store/modules/Planning/Planning.actions';
import { getUuid } from '../../helpers/UUID';
// eslint-disable-next-line import/no-cycle
import { filtersLocalStorage } from '../Filters';
import { RemoveSummaryScenery } from '../../store/modules/Summary/Summary.actions';
import getNewSelectedScenery from './ScenariosUtil';
import IconCopy from '../../assets/images/copy-gradient.svg';
import IconDelete from '../../assets/images/delete.svg';
import { StyleTabsItemProps } from '../StyledTabs/StyledTab';
import useScenarios from './useScenarios';
import ButtonApp from '../ButtonApp';
import useFilters from '../Filters/useFilters';
import classesGTM from '../../config';

interface Props {
  onSelectScenery?(scenery: number): void;
  onAddSceneryClick?(): void;
}

const Scenarios = ({ onAddSceneryClick, onSelectScenery }: Props) => {
  const { simulation } = useAppSelector((state) => state);
  const { getFiltersByScenery } = useFilters();
  const dispatch = useAppDispatch();
  const scenariosRef = useRef<ScenariosStore>(scenariosLocalStorage());
  const navigate = useNavigate();
  const { getCopyScenery, setFiltersAndPlanningOnScenery } = useScenarios();
  const { path, childrens } = paths.home.childrens.simulation;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [scenerySelected, setScenerySelected] = useState<SimulationScenery>();

  const handleClickScenery = (sceneryNumber: number) => {
    if (onSelectScenery) onSelectScenery(sceneryNumber);
    const scenery = simulation.scenarios.find(
      (sc) => sc.sceneryNumber === sceneryNumber
    );

    if (scenery) {
      const filters = getFiltersByScenery(scenery.uuid);

      const pathName = scenery.lastPath;
      const stateRoute =
        pathName === childrens.filters
          ? { typeTV: filters?.data?.typeTV || filters?.partialData?.typeTV }
          : null;

      navigate(`${path}/${sceneryNumber}/${pathName}`, {
        state: stateRoute
      });
    }
  };

  const createNewScenery = () => {
    const sceneryId = getUuid();

    dispatch(AddScenery({ uuid: sceneryId }));

    const lastScenery = simulation.scenarios[simulation.scenarios.length - 1];
    const newScenery = lastScenery ? lastScenery.sceneryNumber + 1 : 0;

    return { newScenery, sceneryId };
  };

  const handleAddScenery = () => {
    if (onAddSceneryClick) onAddSceneryClick();

    const { newScenery } = createNewScenery();
    navigate(`${path}/${newScenery}/${childrens.filters}`, {
      state: { typeTV: null }
    });
  };

  /// FEATURE COPIA
  const handleCopyScenary = async (index: number) => {
    const sceneryCopy = simulation.scenarios.find(
      (scenery) => scenery.sceneryNumber === index
    );
    if (sceneryCopy) {
      const { newScenery, sceneryId } = createNewScenery();
      const data = getCopyScenery(sceneryCopy);

      navigate(`${path}/${newScenery}/${childrens.filters}`, {
        state: { typeTV: data.filters?.typeTV }
      });

      const sceneryCopyData = {
        ...data,
        uuid: sceneryId,
        sceneryNumber: newScenery,
        agencyUf: simulation.me_filial_agencia
      } as SimulationDataScenery;

      await setFiltersAndPlanningOnScenery(sceneryCopyData);
    }
  };

  const handleRemoveScenery = (index: number) => {
    const sceneryRemove = simulation.scenarios.find(
      (scenery) => scenery.sceneryNumber === index
    );
    if (sceneryRemove) {
      setScenerySelected(sceneryRemove);
      setOpenModal(true);
    }
  };

  const handleOkClick = () => {
    if (scenerySelected?.uuid) {
      const newScenerySelected = getNewSelectedScenery(
        simulation.scenarios,
        scenerySelected.sceneryNumber,
        simulation.currentScenery
      );

      dispatch(RemoveScenery(scenerySelected.uuid));
      dispatch(RemoveFiltersScenery(scenerySelected.uuid));
      dispatch(RemoveProgramsScenery(scenerySelected.uuid));
      dispatch(RemovePlanningProgramsScenery(scenerySelected.uuid));
      dispatch(RemoveSummaryScenery(scenerySelected.uuid));
      filtersLocalStorage(scenerySelected.uuid).remove();

      if (newScenerySelected) {
        const filters = getFiltersByScenery(newScenerySelected.uuid);

        const pathName = newScenerySelected.lastPath;
        navigate(`${path}/${newScenerySelected.sceneryNumber}/${pathName}`, {
          state:
            pathName === childrens.filters
              ? {
                  typeTV: filters?.data?.typeTV || filters?.partialData?.typeTV
                }
              : null
        });
      }
    }

    setOpenModal(false);
  };

  useEffect(() => {
    const currentScenery = scenariosRef.current.currentScenery.get();
    if (currentScenery) {
      dispatch(SetCurrentScenery(currentScenery));
    }
  }, [dispatch]);

  useEffect(() => {
    scenariosRef.current.scenarios.set(
      simulation.scenarios.map((scenery) => ({
        name: scenery.name,
        uuid: scenery.uuid
      }))
    );
  }, [dispatch, simulation.scenarios]);

  useEffect(() => {
    scenariosRef.current?.currentScenery.set(simulation.currentScenery);
    scenariosRef.current?.currentSceneryId.set(simulation.currentSceneryId);
  }, [simulation.currentScenery, simulation.currentSceneryId]);

  const items: StyleTabsItemProps[] = [
    {
      id: getUuid(),
      icon: IconCopy,
      title: 'Duplicar',
      handleClick: handleCopyScenary,
      classes: { tagManager: classesGTM.tabs.copyScenery }
    },
    {
      id: getUuid(),
      icon: IconDelete,
      title: 'Remover',
      handleClick: handleRemoveScenery,
      classes: { tagManager: classesGTM.tabs.removeScenery }
    }
  ];

  const WIDTH_TAB = 125;
  const WIDTH_BUTTON_ADD = 35;
  const WIDTH_BUTTON_ARROWS = 40;
  const WIDTH_ALL_BUTTONS = WIDTH_BUTTON_ADD + WIDTH_BUTTON_ARROWS;

  return (
    <div className="scenarios">
      {scenerySelected ? (
        <ModalScenarios
          onClickCancel={() => setOpenModal(false)}
          onClickOk={handleOkClick}
          open={openModal}
          scenery={scenerySelected}
        />
      ) : null}
      <StyledTabs
        allowScrollButtonsMobile
        onChange={(_, value: number) => handleClickScenery(value)}
        scrollButtons="auto"
        value={simulation.currentScenery}
        variant={
          simulation.scenarios.length <
          Math.floor((window.innerWidth - WIDTH_ALL_BUTTONS) / WIDTH_TAB)
            ? 'standard'
            : 'scrollable'
        }
      >
        {simulation.scenarios.map((scenery) => (
          <StyledTab
            key={scenery.uuid}
            index={scenery.sceneryNumber}
            label={scenery.name}
            tabOptions={items}
          />
        ))}
      </StyledTabs>
      <ButtonApp
        classes={{
          button: `btn--square ma-2 scenarios__button ${classesGTM.tabs.addScenery}`
        }}
        onClick={handleAddScenery}
        title="+"
      />
    </div>
  );
};

Scenarios.defaultProps = {
  onAddSceneryClick: undefined,
  onSelectScenery: undefined
};

export default Scenarios;
