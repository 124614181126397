import { SortColumn } from 'react-data-grid';
import { FiltersData, ClosedPeriodTypesEnum } from '../Filters/Filters.types';
import { TypeTV } from '../../../services/shared/Api.service';

export enum ProgramsTypes {
  LOAD_PROGRAMS_REQUEST = '@programs/LOAD_PROGRAMS_REQUEST',
  LOAD_PROGRAMS_SUCCESS = '@programs/LOAD_PROGRAMS_SUCCESS',
  LOAD_PROGRAMS_ERROR = '@programs/LOAD_PROGRAMS_ERROR',
  SET_HIDE_DAYS_OF_WEEK = 'programs/SET_HIDE_DAYS_OF_WEEK',
  CLEAR_PROGRAMS = '@programs/CLEAR_PROGRAMS',
  REMOVE_PROGRAMS_SCENERY = '@programs/REMOVE_PROGRAMS_SCENERY',
  SET_PROGRAMS_SORTED_COLUMNS = '@programs/SET_PROGRAMS_SORTED_COLUMNS',
  RESET_RANKING = '@programs/RESET_RANKING'
}

type Metrics = {
  audience: number;
  cpm: number;
  cpp: number;
  affinity: number;
  impacts: number;
};

type MetricsByWeekDay = {
  monday?: Metrics;
  tuesday?: Metrics;
  wednesday?: Metrics;
  thursday?: Metrics;
  friday?: Metrics;
  saturday?: Metrics;
  sunday?: Metrics;
};

export type ProgramMetricsPerTarget = {
  target: string;
  avgAudience: number;
  avgAudiencePercent: number;
  avgAudienceDomicilePercentage: number;
  avgImpacts: number;
  avgNumOfDomicilesWithTv: number;
  avgUnivTargetPercentage: number;
  affinity: number;
  metricsByWeekDay: MetricsByWeekDay;
  avgCpp: number;
  avgCpm: number;
  share: number;
  univTarget: number;
};

export type PriceByDate = {
  competency: string;
  secondary: string;
  price: number;
};

export enum OpenTVAudienceOrigin {
  PROGRAMA = 'PROGRAMA',
  MINUTO = 'MINUTO'
}

export interface Program {
  type: 'programs';
  metricsPerTarget: ProgramMetricsPerTarget[];
  avgProgramStartTime: string;
  avgDuration: number;
  avgProgramEndTime: string;
  channel: string;
  daysExhibition: string[];
  initials: string | null;
  name: string;
  avgPrice: number;
  pricesByDate: PriceByDate[];
  origem: OpenTVAudienceOrigin;
  regularNotebookName: string;
  regularInitials: string;
}
export interface ProgramAction {
  type: ProgramsTypes;
  payload: {
    rows?: Program[];
    count?: number;
    message?: string;
    hideDaysOfWeek?: boolean;
    sortedColumns?: SortColumn[];
    sceneryId: string;
  };
}

export interface ProgramsState {
  readonly data: Program[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
  readonly message?: string;
  readonly hideDaysOfWeek: boolean;
  readonly sortedColumns: SortColumn[];
  readonly sceneryId: string;
}

export type ProgramsRequest = FiltersData;

export interface ProgramsQueryParams {
  market: string;
  channel: string;
  targets: string[];
  agencyUf: string;
  dateFromAudience: Date | string;
  dateToAudience: Date | string;
  dateBuy: Date | string;
  secondary?: number;
  typeTV: TypeTV.OPEN | TypeTV.CLOSED;
  dateCampaignFrom: Date | string;
  dateCampaignTo: Date | string;
  closedPeriodType:
    | ClosedPeriodTypesEnum.QUARTER
    | ClosedPeriodTypesEnum.SEMESTER
    | ClosedPeriodTypesEnum.CLOSED_YEAR
    | null;
}

export interface ProgramsResponse {
  data: {
    rows: Program[];
    count: number;
  };
  status: number;
  msg: string;
}
