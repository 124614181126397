import omitProps from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback } from 'react';
import * as Yup from 'yup';
import {
  isNotebook,
  isEqualProgram,
  TV_ABERTA_GLOBO
} from '../../helpers/Utils';
import {
  SimulationDataScenery,
  SimulationScenery
} from '../../store/modules/Simulation/Simulation.types';
// eslint-disable-next-line import/no-cycle
import { filtersLocalStorage } from '../Filters';
import paths from '../../routes/paths';
import useFilters from '../Filters/useFilters';
import { useAppDispatch } from '../../hooks';
import {
  SaveFiltersFormData,
  SaveFiltersFormDataError,
  SavePartialFormData
} from '../../store/modules/Filters/Filters.actions';
import {
  SelectTargetPlanningByScenery,
  SetPlanningProgramsByScenery,
  SetTotalizers
} from '../../store/modules/Planning/Planning.actions';
import { FiltersData } from '../../store/modules/Filters/Filters.types';
import getPrograms from '../../services/MediaSimulator/Programs.service';
import {
  LoadingProgramsRequest,
  LoadProgramsFailure,
  LoadProgramsSuccess
} from '../../store/modules/Programs/Programs.actions';
import handleErrors, { YupError } from '../../helpers/HandleErrors';
import { Program } from '../../store/modules/Programs/Programs.types';
import { PlanningRow } from '../../store/modules/Planning/Planning.types';
import {
  calcMetricsOfInsertions,
  calcTotalizers
} from '../../helpers/CalcsSimulation';
import usePlanningRedux from '../Planning/usePlanningRedux';
import { getNotebookMarket } from '../../services/MediaSimulator/Markets.service';
import validateFields from '../Filters/Filters.validations';

const { childrens } = paths.home.childrens.simulation;

export default function useScenarios() {
  const { getFiltersByScenery } = useFilters();
  const { getPlanningByScenery } = usePlanningRedux();

  const dispatch = useAppDispatch();

  const getAsyncPrograms = useCallback(
    async (sceneryId: string, filters: FiltersData) => {
      let allPrograms: Program[] = [];
      try {
        await validateFields(
          filters,
          !!filters?.dates?.audience?.closedPeriod?.year
        );
        dispatch(
          SaveFiltersFormData({
            data: filters,
            sceneryId
          })
        );

        dispatch(LoadingProgramsRequest(sceneryId));

        allPrograms = await getPrograms(filters, filters.market.signal);
        dispatch(LoadProgramsSuccess(allPrograms, sceneryId));
      } catch (error) {
        let errors: YupError | undefined;
        if (error instanceof Yup.ValidationError) {
          errors = handleErrors(error);
          dispatch(SaveFiltersFormDataError(sceneryId, errors));
        } else {
          const message =
            error instanceof Error
              ? error.message
              : 'Ops, houve um problema ao realizar a busca.';

          dispatch(LoadProgramsFailure(message, sceneryId));
        }
      }
      return allPrograms;
    },
    [dispatch]
  );

  const getAudienceDatesNotebookMarket = useCallback(
    async (signal: string, agencyUf: string) => {
      try {
        const { data } = await getNotebookMarket(signal, agencyUf);

        return data.row;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    []
  );

  const serializeSceneryFilters = useCallback(
    async (sceneryData: SimulationDataScenery) => {
      if (sceneryData.filters) {
        // set channel description in filters of a saved simulation. From Globo to TV Globo
        let serializedChannels = sceneryData.filters.channels;
        if (sceneryData.filters.channels?.length) {
          serializedChannels = sceneryData.filters.channels.map((channel) =>
            channel.description.toLowerCase() === 'globo'
              ? { ...channel, description: TV_ABERTA_GLOBO }
              : channel
          );
        }

        const filters = {
          ...sceneryData.filters,
          agencyUf: sceneryData.agencyUf,
          channels: serializedChannels
        };

        if (isNotebook(filters.market) && filters.market?.signal) {
          const market = await getAudienceDatesNotebookMarket(
            filters.market.signal,
            filters.agencyUf as string
          );

          if (market?.maxDate && market?.minDate) {
            filters.market = { ...filters.market, ...market };

            if (filters.dates?.audience) {
              filters.dates.audience.to = market.maxDate;
              filters.dates.audience.from = market.minDate;
            }
          }
        }

        dispatch(SavePartialFormData(sceneryData.uuid, filters));

        return filters;
      }

      return null;
    },
    [getAudienceDatesNotebookMarket, dispatch]
  );

  const serializePrograms = useCallback(
    (
      planningPrograms: PlanningRow[],
      allPrograms: Program[],
      target: string | undefined
    ) => {
      return planningPrograms.reduce<PlanningRow[]>((allPlanning, program) => {
        const copyProgram = cloneDeep(program);
        const programRanking = allPrograms.find(isEqualProgram(program));

        if (!programRanking) return allPlanning;

        if (
          !['comercial break', 'break comercial'].includes(
            program.format.toLowerCase()
          )
        ) {
          copyProgram.format = '';
          copyProgram.amount = 0;
          copyProgram.negotiatedValue = 0;
          copyProgram.pricesByDate = copyProgram.pricesByDate.map(
            (priceByDate) => ({ ...priceByDate, price: 0 })
          );
        }

        const metrics = calcMetricsOfInsertions(
          programRanking.metricsPerTarget,
          copyProgram.pricesByDate,
          copyProgram.insertionsByDay,
          copyProgram.discount,
          target || ''
        );

        const newProgram: PlanningRow = {
          ...programRanking,
          id: copyProgram.id,
          type: 'planningRow',
          pricesByDate: copyProgram.pricesByDate,
          format:
            copyProgram.format.toLowerCase() === 'break comercial'
              ? 'comercial break'
              : copyProgram.format,
          secondary: copyProgram.secondary,
          insertionsByDay: copyProgram.insertionsByDay,
          insertions: copyProgram.insertions,
          discount: copyProgram.discount,
          amount: copyProgram.amount,
          negotiatedValue: copyProgram.negotiatedValue,
          addAt: copyProgram.addAt,
          totalImpacts: metrics.totalImpacts,
          metricsByCompetency: metrics.metricsByCompetency
        };

        allPlanning.push(newProgram);

        return allPlanning;
      }, []);
    },
    []
  );

  const setFiltersAndPlanningOnScenery = useCallback(
    async (sceneryData: SimulationDataScenery) => {
      const filters = await serializeSceneryFilters(sceneryData);

      if (filters) {
        const allPrograms = await getAsyncPrograms(
          sceneryData.uuid,
          filters as FiltersData
        );

        if (allPrograms.length) {
          if (sceneryData?.planning?.target) {
            dispatch(
              SelectTargetPlanningByScenery(
                sceneryData.planning.target,
                sceneryData.uuid
              )
            );
          }

          if (sceneryData.planning?.programs?.length) {
            /* 
            set channel description in planning of a saved simulation. From Globo to TV Globo
            set format in planning of a saved simulation. From break comercial to comercial break 
          */
            const serializedPrograms = serializePrograms(
              sceneryData.planning.programs,
              allPrograms,
              sceneryData.planning.target
            );

            dispatch(
              SetPlanningProgramsByScenery(serializedPrograms, sceneryData.uuid)
            );

            const totalizers = calcTotalizers(
              serializedPrograms,
              sceneryData.planning?.target || ''
            );

            dispatch(
              SetTotalizers(
                totalizers.totalAmount,
                totalizers.totalNegotiated,
                totalizers.avgDiscount,
                totalizers.totalInsertions,
                totalizers.totalTrpOrGrp,
                sceneryData.uuid
              )
            );
          }
        }
      }
    },
    [dispatch, getAsyncPrograms, serializeSceneryFilters, serializePrograms]
  );

  const getCopyScenery = (scenery: SimulationScenery) => {
    const planning = getPlanningByScenery(scenery.uuid);

    const filtersStorage = filtersLocalStorage(scenery.uuid);
    const filtersOfScenery = getFiltersByScenery(scenery.uuid);

    let filters;
    if (
      !planning?.planningPrograms?.length &&
      scenery.lastPath === childrens.filters
    ) {
      const currentFilters = filtersOfScenery?.ref?.current?.getData();

      filters =
        currentFilters ||
        filtersOfScenery?.partialData ||
        filtersOfScenery?.data ||
        filtersStorage.get();
    } else {
      filters = filtersOfScenery?.data;
    }

    return cloneDeep({
      ...omitProps(scenery, 'lastPath'),
      filters,
      planning: planning?.planningPrograms?.length
        ? { programs: planning?.planningPrograms, target: planning?.target }
        : undefined
    });
  };

  return { getCopyScenery, setFiltersAndPlanningOnScenery };
}
