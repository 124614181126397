import * as Yup from 'yup';
import { FiltersData } from '../../store/modules/Filters/Filters.types';

// Define validation schema for the audience part using Yup context (i.e., $closedPeriod).
const audienceSchema = Yup.object().shape({
  from: Yup.date()
    .nullable()
    .when('$closedPeriod', {
      is: false,
      then: Yup.date()
        .typeError('Informe uma data válida.')
        .required('Data "de" do período de audiência é obrigatória.'),
      otherwise: Yup.date().nullable()
    }),
  to: Yup.date()
    .nullable()
    .when('$closedPeriod', {
      is: false,
      then: Yup.date()
        .typeError('Informe uma data válida.')
        .required('Data "até" de audiência é obrigatória.'),
      otherwise: Yup.date().nullable()
    }),
  closedPeriod: Yup.mixed().when('$closedPeriod', {
    is: true,
    then: Yup.object()
      .shape({
        year: Yup.date()
          .nullable()
          .typeError('Informe uma data válida para o ano.')
          .required('Ano é obrigatório.'),
        type: Yup.string().required('Periodo de Audiência é obrigatório.'),
        value: Yup.object()
          .shape({
            label: Yup.string().required('Periodo é obrigatório.'),
            value: Yup.string().required('Periodo é obrigatório.')
          })
          .nullable()
          .required('Periodo é obrigatório.')
      })
      .required('Dados de período fechado são obrigatórios.'),
    otherwise: Yup.mixed().notRequired()
  })
});

// Build the overall schema for FiltersData.
const schemaOpenedTV = Yup.object().shape({
  channels: Yup.array(
    Yup.object().shape({
      channelId: Yup.number().required(),
      description: Yup.string().required()
    })
  )
    .min(1, 'Obrigatório informar no mínimo 1 canal.')
    .required('Canais são obrigatórios.'),

  dates: Yup.object().shape({
    audience: audienceSchema,
    buy: Yup.date()
      .typeError('Informe uma data válida.')
      .required('Data de compra é obrigatória.'),
    campaign: Yup.object().shape({
      from: Yup.date()
        .typeError('Informe uma data válida.')
        .required('Data "de" da campanha é obrigatória.'),
      to: Yup.date()
        .typeError('Informe uma data válida.')
        .required('Data "até" da campanha é obrigatória.')
    })
  }),

  market: Yup.object()
    .shape({
      name: Yup.string().required(),
      signal: Yup.string().required()
    })
    .nullable()
    .required('Praça é obrigatória.'),

  targets: Yup.array(Yup.string())
    .max(2, 'Selecione no máximo 2 targets (primário e secundário).')
    .min(1, 'Selecione no mínimo 1 target (primário e secundário).')
    .required('Selecione targets primário e secundário.'),

  typeTV: Yup.string().required('Selecione uma opção de TV.')
});

// The validateFields function passes the context { closedPeriod } so that nested validations in audience can use it.
const validateFields = (
  formData: Partial<FiltersData>,
  closedPeriod: boolean
) => {
  return schemaOpenedTV.validate(formData, {
    abortEarly: false,
    context: { closedPeriod }
  });
};

export default validateFields;
