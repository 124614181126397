import { FormatterProps } from 'react-data-grid';
import { GridPaginationHeader } from '../GridPagination';
import { SummaryRowDetail } from './types';
import { GridColumn } from '../GridPagination/types';
import {
  formatterNumber,
  formatterPercent,
  formatterCurrency
} from '../../helpers/Formatters';
import { classMaskOrUnmask } from '../../helpers/Utils';
import useFilters from '../Filters/useFilters';
import { OpenTVAudienceOrigin } from '../../store/modules/Programs/Programs.types';
import { TypeTV } from '../../services/shared/Api.service';

export default function useSummaryGridDetail() {
  const { getCurrentFilters } = useFilters();
  const typeTV = getCurrentFilters()?.data?.typeTV;
  const getColumns = (
    target: string
  ): readonly GridColumn<SummaryRowDetail>[] => [
    {
      key: 'initials',
      name: 'Sigla',
      width: 20,
      headerCellClass: 'grid-col__separate--none',
      cellClass: (row: SummaryRowDetail) =>
        `${classMaskOrUnmask(false)} ${
          row.initials
            ? 'grid-col__separate--none'
            : 'grid-col__separate--none text-center'
        }`,
      formatter: ({ row }) => <>{row?.initials ? row.initials : '-'}</>
    },
    {
      key: 'name',
      name: 'Programa',
      width: 200,
      cellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      headerCellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      formatter: ({ row }) => (
        <>
          {typeTV === TypeTV.CLOSED ||
          row?.origem === OpenTVAudienceOrigin.PROGRAMA
            ? `${row?.name}`
            : `${row?.name} *`}
        </>
      )
    },
    {
      key: 'programTime',
      name: 'Horário',
      width: 150,
      cellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      headerCellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`
    },
    {
      key: 'daysExhibition',
      name: 'Dias',
      width: 130,
      cellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      headerCellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`
    },
    {
      key: 'secondary',
      name: 'Secundagem',
      width: 130,
      headerRenderer: (props) =>
        GridPaginationHeader({
          alignment: 'right',
          title: 'Secundagem',
          ...props
        }),
      cellClass: `${classMaskOrUnmask(
        false
      )} grid-col__separate--none text-right`,
      headerCellClass: 'grid-col__separate--none'
    },
    {
      key: 'totalInserts',
      name: 'Total Inserções',
      width: 80,
      headerRenderer: (props) =>
        GridPaginationHeader({
          alignment: 'right',
          subtitle: 'inserções',
          title: 'Total',
          ...props
        }),
      cellClass: `${classMaskOrUnmask(
        false
      )} grid-col__separate--none text-right`,
      headerCellClass: 'grid-col__separate--none'
    },
    {
      key: 'trp',
      name: `GRP/TRP (${target})`,
      headerRenderer: (props) =>
        GridPaginationHeader({
          alignment: 'right',
          title: 'GRP/TRP',
          subtitle: target,
          dataMaskSubtitle: true,
          ...props
        }),
      width: 150,
      cellClass: `${classMaskOrUnmask(
        false
      )} grid-col__separate--none text-right`,
      headerCellClass: 'grid-col__separate--none',
      formatter: ({ row }: FormatterProps<SummaryRowDetail>) => (
        <>{formatterNumber(row.trp, { fractionDigits: 5 })}</>
      )
    },
    {
      key: 'totalImpacts',
      name: 'Total Impactos',
      width: 150,
      headerRenderer: (props) =>
        GridPaginationHeader({
          alignment: 'right',
          title: 'Total',
          subtitle: 'impactos',
          ...props
        }),
      formatter: ({ row }: FormatterProps<SummaryRowDetail>) => (
        <>{formatterNumber(row.totalImpacts, { fractionDigits: 0 })}</>
      ),
      cellClass: `${classMaskOrUnmask(
        false
      )} grid-col__separate--none text-right`,
      headerCellClass: 'grid-col__separate--none'
    },
    {
      key: 'cpm',
      name: 'CPM Impactos',
      width: 150,
      headerRenderer: (props) =>
        GridPaginationHeader({
          alignment: 'right',
          title: 'CPM',
          subtitle: 'impactos',
          ...props
        }),
      formatter: ({ row }: FormatterProps<SummaryRowDetail>) => (
        <>{formatterNumber(row.cpm, { fractionDigits: 1 })}</>
      ),
      cellClass: `${classMaskOrUnmask(
        false
      )} grid-col__separate--none text-right`,
      headerCellClass: 'grid-col__separate--none'
    },
    {
      key: 'cpp',
      name: `CPP (${target})`,
      headerRenderer: (props) =>
        GridPaginationHeader({
          alignment: 'right',
          title: 'CPP',
          subtitle: target,
          dataMaskSubtitle: true,
          ...props
        }),
      width: 150,
      cellClass: `${classMaskOrUnmask(
        false
      )} grid-col__separate--none text-right`,
      headerCellClass: 'grid-col__separate--none',
      formatter: ({ row }: FormatterProps<SummaryRowDetail>) => (
        <>{formatterNumber(row.cpp, { fractionDigits: 1 })}</>
      )
    },
    {
      key: 'totalInvestment',
      name: 'Total Investimento Inicial',
      width: 150,
      headerRenderer: (props) =>
        GridPaginationHeader({
          alignment: 'right',
          title: 'Total',
          subtitle: 'invest. inicial',
          ...props
        }),
      formatter: ({ row }: FormatterProps<SummaryRowDetail>) => (
        <>{formatterCurrency(row.totalInvestment, { fractionDigits: 1 })}</>
      ),
      cellClass: `${classMaskOrUnmask(
        false
      )} grid-col__separate--none text-right`,
      headerCellClass: 'grid-col__separate--none'
    },
    {
      key: 'totalAvgDiscount',
      name: 'Total Desconto Médio',
      exportXlsxFormat: '0.000%',
      headerRenderer: (props) =>
        GridPaginationHeader({
          alignment: 'right',
          title: 'Total',
          subtitle: 'desconto médio',
          ...props
        }),
      formatter: ({ row }: FormatterProps<SummaryRowDetail>) => (
        <>{formatterPercent(row.totalAvgDiscount, { fractionDigits: 3 })}</>
      ),
      cellClass: `${classMaskOrUnmask(
        false
      )} grid-col__separate--none text-right`,
      headerCellClass: 'grid-col__separate--none'
    },
    {
      key: 'totalNegotiated',
      name: 'Total Investimento Negociado',
      width: 150,
      headerRenderer: (props) =>
        GridPaginationHeader({
          alignment: 'right',
          title: 'Total',
          subtitle: 'invest. negociado',
          ...props
        }),
      formatter: ({ row }: FormatterProps<SummaryRowDetail>) => (
        <>{formatterCurrency(row.totalNegotiated, { fractionDigits: 1 })}</>
      ),
      cellClass: `${classMaskOrUnmask(
        false
      )} grid-col__separate--none text-right`,
      headerCellClass: 'grid-col__separate--none'
    }
  ];

  return { getColumns };
}
