import './Planning.scss';
import React, { useState, useEffect, useMemo } from 'react';
import { FormControl, SelectChangeEvent, Box } from '@mui/material';
import { format } from 'date-fns';
import { RowsChangeData, SortColumn } from 'react-data-grid';
import Skeleton from '@mui/material/Skeleton';
import SelectInput from '../Form/SelectInput';
import GridPagination from '../GridPagination';
import SourceFooter from '../SourceFooter';
import usePlanning from './usePlanning';
import useFilters from '../Filters/useFilters';
import { useAppDispatch, useAppMediaQuery } from '../../hooks';
import Info from '../../assets/images/icon-info.svg';
import IconMinus from '../../assets/images/IconMinus.svg';
import IconCopy from '../../assets/images/copy.svg';
import SetHideDaysOfWeek, {
  SelectTargetPlanning,
  SetPlanningPrograms,
  SetPlanningSortedColumns
} from '../../store/modules/Planning/Planning.actions';
import CheckboxInput from '../Form/CheckboxInput';
import Information, { InformationAlert } from '../Information';
import usePlanningRedux from './usePlanningRedux';
import ModalPlanning from './ModalPlanning';
import ModalDiscount from './ModalDiscount';
import { PlanningRow } from '../../store/modules/Planning/Planning.types';
import {
  calcDiscount,
  calcTotalAmount,
  calcMetricsOfInsertions
} from '../../helpers/CalcsSimulation';
import ButtonApp from '../ButtonApp';
import { attributeMaskOrUnmask } from '../../helpers/Utils';
import classesGTM from '../../config';
import AppSVG from '../SVG/AppSVG';

const Planning = () => {
  const { getCurrentFilters } = useFilters();
  const planning = usePlanningRedux().currentPlanning;
  const currentFilter = getCurrentFilters();
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openModalDiscount, setOpenModalDiscount] = useState(false);

  const downMd = useAppMediaQuery('md', 'down');

  const getBuyDate = () => {
    if (currentFilter?.data?.dates.buy) {
      return new Date(currentFilter.data.dates.buy);
    }
    return new Date();
  };

  const { columns, sortedRows, handleConfirmInsertion, actualProgram } =
    usePlanning(
      currentFilter,
      planning.target || currentFilter?.data?.targets[0] || '',
      planning.hideDaysOfWeek || false,
      setOpenModal
    );

  const MessageNoRows = (
    <div className="pr-5 pl-5 no-message">
      <div>
        <AppSVG
          className="no-pointer-events"
          height={25}
          loader={<Skeleton height={25} width={24} />}
          src={Info}
          width={24}
        />
      </div>
      <div>Nenhum programa selecionado.</div>
    </div>
  );

  const calcRowTotalizers = (row: PlanningRow): PlanningRow => {
    const insertionsByDay = row.insertionsByDay.filter(
      (insertions) => insertions.value > 0
    );

    const totalAmount = calcTotalAmount(insertionsByDay, row.pricesByDate);
    const discount = parseFloat(row.discount.toString().replace(',', '.'));
    const totalDiscount = calcDiscount(totalAmount, discount);

    return {
      ...row,
      discount,
      amount: Number(totalAmount.toFixed(3)),
      negotiatedValue: totalDiscount
    };
  };

  const handleRowChange = async (
    rows: PlanningRow[],
    data: RowsChangeData<PlanningRow>
  ) => {
    const rowsUpdated = data.indexes
      .map((idx) => rows[idx])
      .map(calcRowTotalizers);

    if (rowsUpdated.length) {
      const rowsNotUpdated = rows.filter(
        (row) => !rowsUpdated.some((up) => up.id === row.id)
      );

      dispatch(SetPlanningPrograms([...rowsNotUpdated, ...rowsUpdated]));
    }
  };

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(SetHideDaysOfWeek(event.target.checked));
  };

  const onSortColumnsChange = (sortedColumn: SortColumn[]) => {
    dispatch(SetPlanningSortedColumns(sortedColumn.slice(-1)));
  };

  const getTargets = useMemo(
    () =>
      currentFilter?.data?.targets.map((t) => ({
        value: t,
        label: t
      })) || [],
    [currentFilter]
  );

  const handleSelectChange = (e: SelectChangeEvent) => {
    dispatch(SelectTargetPlanning(e.target.value));
  };

  const handleOkClick = () => {
    handleConfirmInsertion();
    setOpenModal(false);
  };

  const handleApplyDiscount = (channel: string, discount: string) => {
    setOpenModalDiscount(false);
    if (!!channel.length && discount) {
      const rows = planning.planningPrograms.map((pp) => {
        if (pp.channel === channel) {
          return {
            ...pp,
            discount:
              Number(discount.trim().replace(' ', '').replace(',', '.')) || 0
          };
        }
        return pp;
      });
      const rowsUpdated = rows.map((row) => {
        const rowTotalizers = calcRowTotalizers(row);
        const metrics = calcMetricsOfInsertions(
          row.metricsPerTarget,
          row.pricesByDate,
          row.insertionsByDay,
          row.discount,
          planning.target
        );

        return { ...rowTotalizers, ...metrics };
      });

      if (rowsUpdated.length) {
        const rowsNotUpdated = rows.filter(
          (row) => !rowsUpdated.some((up) => up.id === row.id)
        );

        dispatch(SetPlanningPrograms([...rowsNotUpdated, ...rowsUpdated]));
      }
    }
  };
  useEffect(() => {
    dispatch(SelectTargetPlanning(planning.target || getTargets[0]?.value));
  }, [dispatch, getTargets, planning.target]);

  const HeaderGrid = (
    <FormControl className="planning__form-control" fullWidth>
      <div className="planning__select">
        <SelectInput
          dataClarityMask
          defaultValue={planning.target || getTargets[0]?.value}
          disabled={getTargets.length === 1}
          fullWidth
          label="Target"
          name="target"
          onChange={handleSelectChange}
          options={getTargets}
          size="small"
        />
      </div>
      <ButtonApp
        classes={{
          button: 'planning__btn btn--med btn--transparent',
          tagManager: classesGTM.planning.distributeDiscount
        }}
        onClick={() => setOpenModalDiscount(true)}
        title="Distribuir desconto"
      />
      <CheckboxInput
        checked={planning.hideDaysOfWeek}
        classes={{ root: 'plim-gray3', checked: 'plim-gray5' }}
        classTagManager={classesGTM.planning.hideDaysOfWeek}
        label="Ocultar os dias da semana"
        labelClasses={{ label: 'planning__checkbox' }}
        onChange={handleChecked}
        size="small"
      />
    </FormControl>
  );

  const messageInfo = (
    <>
      Clique no botão&nbsp;
      <AppSVG className="mr-0" height={20} src={IconMinus} width={20} />
      &nbsp;na tabela abaixo para retirar a linha do Planejamento ou no
      botão&nbsp;
      <AppSVG className="mr-0" height={20} src={IconCopy} width={20} />
      &nbsp;para duplicar a linha.
    </>
  );

  const buyDate = new Date(getBuyDate());
  const lastDayOfMonth = new Date(
    buyDate.getFullYear(),
    buyDate.getMonth() + 1,
    0
  );

  return (
    <>
      <ModalPlanning
        actualProgram={actualProgram}
        onClickCancel={() => setOpenModal(false)}
        onClickOk={handleOkClick}
        open={openModal}
      />
      <ModalDiscount
        onClickCancel={() => setOpenModalDiscount(false)}
        onClickOk={(channel: string, discount: string) => {
          handleApplyDiscount(channel, discount);
        }}
        open={openModalDiscount}
      />
      <Information message={messageInfo} />
      <GridPagination
        className="planning__grid--height"
        classTagManagerDownXlxs={classesGTM.planning.exportXlsx}
        columns={columns}
        countData={planning.planningPrograms.length}
        defaultColumnOptions={{ resizable: true }}
        exportXlsxFileName={`Planning-${planning.target}`}
        hasExportGridToXlxs
        hasSearch={false}
        headerContainer={HeaderGrid}
        loading={planning.loading}
        renderers={{ noRowsFallback: MessageNoRows }}
        onRowsChange={handleRowChange}
        onSortColumnsChange={onSortColumnsChange}
        rowHeight={51}
        rows={sortedRows}
        sortColumns={planning.sortedColumns}
        xlsxSheetName={planning.target}
      />
      <Box
        alignItems={downMd ? 'initial' : 'center'}
        className="mt-4"
        display="flex"
        flexDirection={downMd ? 'column' : 'row'}
        gap={downMd ? 2 : undefined}
        justifyContent="space-between"
        {...attributeMaskOrUnmask(false)}
      >
        <InformationAlert
          message={`Este planejamento é válido somente até ${format(
            lastDayOfMonth,
            'dd/MM/yyyy'
          )}`}
        />
      </Box>
      <SourceFooter displayUniverse />
    </>
  );
};

export default Planning;
