import { TypeTV } from '../../../services/shared/Api.service';

export enum MarketsTypes {
  LOAD_MARKETS_REQUEST = '@markets/LOAD_MARKETS_REQUEST',
  LOAD_MARKETS_SUCCESS = '@markets/LOAD_MARKETS_SUCCESS',
  LOAD_MARKETS_ERROR = '@markets/LOAD_MARKETS_ERROR'
}

export type Market = {
  signal: string;
  name: string;
  ibopeName: string;
  id: number;
  notebook: 0 | 1;
  maxDate: string | null;
  minDate: string | null;
  regularPlaza?: string;
  nm_regularPlaza?: string;
  channels?: string[];
};

export interface MarketAction {
  type: MarketsTypes;
  payload: {
    rows: Market[];
    count: number;
    message?: string;
    typeTV: TypeTV.OPEN | TypeTV.CLOSED;
  };
}

export interface MarketsState {
  readonly closedTv: {
    readonly data: Market[];
    readonly loading: boolean;
    readonly error: boolean;
    readonly count: number;
  };
  readonly openedTv: {
    readonly data: Market[];
    readonly loading: boolean;
    readonly error: boolean;
    readonly count: number;
  };
}

export interface MarketsResponse {
  data: {
    rows: Market[];
    count: number;
  };
  status: number;
  msg: string;
}

export interface MarketResponse {
  data: {
    row: Market;
    count: number;
  };
  status: number;
  msg: string;
}
