import { Agency } from '../Agencies/Agencies.types';
import { Client } from '../Clients/Clients.types';
import { FiltersData } from '../Filters/Filters.types';
import { PlanningRow } from '../Planning/Planning.types';
import { Simulation } from '../Simulations/Simulations.types';
import { ISimulationsTypes } from '../SimulationsType/SimulationsType.types';
import { IGroup } from '../Digital/Groups/Groups.types';
import { FiltersDateData } from '../Digital/CampaignPeriod/CampaignPeriod.types';

export enum SimulationTypes {
  NEW_SIMULATION = '@simulation/NEW_SIMULATION',
  ADD_SCENERY_IN_SIMULATION = '@simulation/ADD_SCENERY_IN_SIMULATION',
  SET_SIMULATION = '@simulation/SET_SIMULATION',
  RESET_SIMULATION = '@simulation/RESET_SIMULATION',
  REMOVE_SCENERY_IN_SIMULATION = '@simulation/REMOVE_SCENERY_IN_SIMULATION',
  SET_SCENERY_ID_IN_SIMULATION = '@simulation/SET_SCENERY_ID_IN_SIMULATION',
  SET_CURRENT_SCENERY_IN_SIMULATION = '@simulation/SET_CURRENT_SCENERY_IN_SIMULATION'
}

export type SimulationScenery = {
  uuid: string;
  sceneryNumber: number;
  name: string;
  lastPath: string;
  agencyUf?: string;
};

export interface SimulationPropsData
  extends Omit<Partial<Simulation>, 'name' | 'uuid'> {
  name: string;
  st?: ISimulationsTypes;
  agency?: Agency;
  client?: Client;
  cnpj?: Client;
  uuid: string;
  loading: boolean;
  mustBeLoading?: boolean;
  error: boolean;
  finished: boolean;
  currentScenery: number;
  currentSceneryId: string;
  scenarios: SimulationScenery[];
  groups?: IGroup[];
  DigitalDates?: FiltersDateData;
  submited?: boolean;
}
export interface SimulationProps
  extends Omit<Partial<Simulation>, 'name' | 'uuid'> {
  name: string;
  simulation_type_id?: number;
  me_filial_agencia?: string;
  nu_cgc_cpf_agencia?: string;
  oi_agencia?: number;
  oi_cliente?: number;
  me_cliente?: string;
  cpf_cnpj_cliente?: string;
  uuid: string;
  loading: boolean;
  mustBeLoading?: boolean;
  error: boolean;
  finished: boolean;
  currentScenery: number;
  currentSceneryId: string;
  scenarios: SimulationScenery[];
  groups?: IGroup[];
  DigitalDates?: FiltersDateData;
  submited?: boolean;
  changed?: boolean;
}

type FiltersAndPlanning = {
  filters?: Partial<FiltersData>;
  planning?: {
    programs?: PlanningRow[];
    target?: string;
  };
};

export type SimulationDataScenery = Omit<SimulationScenery, 'lastPath'> &
  FiltersAndPlanning;
export interface SimulationData {
  name: string;
  uuid: string;
  me_filial_agencia?: string;
  nu_cgc_cpf_agencia?: string;
  oi_agencia?: number;
  oi_cliente?: number;
  me_cliente?: string;
  cpf_cnpj_cliente?: string;
  simulation_type_id?: number;
  scenarios: SimulationDataScenery[];
  groups?: IGroup[];
  DigitalDates?: FiltersDateData;
  submited?: boolean;
}

export interface SimulationAction {
  type: SimulationTypes;
  payload: Partial<SimulationProps>;
}

export type SimulationState = Readonly<SimulationProps>;
