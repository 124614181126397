import { useMemo, useCallback } from 'react';
import { SortColumn } from 'react-data-grid';
import getValueProp from 'lodash/get';
import hasProp from 'lodash/has';
import { format } from 'date-fns';
import ActionColumn from '../ActionColumn/ActionColumn';
import {
  sortString,
  sortTime,
  sortNumber,
  sortDaysOfWeek
} from '../../helpers/Sort';
import IconPlus from '../../assets/images/IconPlus.svg';
import IconMinus from '../../assets/images/IconMinus.svg';
import {
  OpenTVAudienceOrigin,
  Program
} from '../../store/modules/Programs/Programs.types';
import { GridPaginationHeader } from '../GridPagination';
import {
  formatterPercent,
  formatterNumber,
  formatterDaysExhibition,
  formatterCurrency
} from '../../helpers/Formatters';
import { useAppDispatch, useAppMediaQuery } from '../../hooks';
import useFilters from '../Filters/useFilters';
import {
  AddPlanningProgram,
  RemovePlanningProgram
} from '../../store/modules/Planning/Planning.actions';
import returTranslatedWeekDay, { weekDaysEN } from '../../helpers/WeekDay';
import { GridColumn } from '../GridPagination/types';
import useRankingRedux from './useRankingRedux';
import { SetProgramsSortedColumns } from '../../store/modules/Programs/Programs.actions';
import { stringToDate } from '../../helpers/Dates';
import isEqualProgramByChannelAndInitialsAndName, {
  classMaskOrUnmask
} from '../../helpers/Utils';
import usePlanningRedux from '../Planning/usePlanningRedux';
import classesGTM from '../../config';
import { TypeTV } from '../../services/shared/Api.service';

const useRanking = () => {
  const { getCurrentFilters } = useFilters();

  const planning = usePlanningRedux().currentPlanning;

  const programs = useRankingRedux().currentPrograms;

  const dispatch = useAppDispatch();

  const mediaQueryUpLg = useAppMediaQuery('lg');
  const mediaQueryUpMd = useAppMediaQuery('md');

  const competenciesPrice = useMemo(() => {
    if (programs.data.length) {
      return programs.data[0].pricesByDate.map((price) =>
        stringToDate(price.competency)
      );
    }
    return [];
  }, [programs.data]);

  const handleAddOrRemove = useCallback(
    (row: Program) => {
      const programsSelecteds = planning.planningPrograms
        ? [...planning.planningPrograms]
        : [];

      const isProgramSelected = programsSelecteds.some(
        isEqualProgramByChannelAndInitialsAndName(row)
      );

      if (isProgramSelected) {
        programsSelecteds
          .filter(isEqualProgramByChannelAndInitialsAndName(row))
          .forEach((p) => dispatch(RemovePlanningProgram(p)));

        return;
      }

      dispatch(AddPlanningProgram(row));
    },
    [dispatch, planning.planningPrograms]
  );

  const onSortColumnsChange = (sortedColumn: SortColumn[]) => {
    dispatch(SetProgramsSortedColumns(sortedColumn.slice(-1)));
  };

  const sortRows = useCallback((): readonly Program[] => {
    if (programs.sortedColumns.length === 0) return programs.data;

    const { columnKey, direction } = programs.sortedColumns[0];
    const newSortedRows: Program[] = [...programs.data];

    if (columnKey.search('metricsPerTarget') !== -1) {
      return newSortedRows.sort((a, b) =>
        sortNumber(
          getValueProp(a, columnKey) as number,
          getValueProp(b, columnKey) as number,
          direction
        )
      );
    }

    if (columnKey.search('pricesByDate') !== -1) {
      return newSortedRows.sort((a, b) =>
        sortNumber(
          getValueProp(a, columnKey) as number,
          getValueProp(b, columnKey) as number,
          direction
        )
      );
    }

    switch (columnKey) {
      case 'channel':
      case 'initials':
      case 'name':
        return newSortedRows.sort((a, b) =>
          sortString(
            (a[columnKey] as string) || '',
            (b[columnKey] as string) || '',
            direction
          )
        );
      case 'daysExhibition':
        return newSortedRows.sort((a, b) =>
          sortDaysOfWeek(
            a[columnKey] as string[],
            b[columnKey] as string[],
            direction
          )
        );
      case 'avgDuration':
        return newSortedRows.sort((a, b) =>
          sortNumber(a[columnKey] as number, b[columnKey] as number, direction)
        );
      case 'avgProgramStartTime':
      case 'avgProgramEndTime':
        return newSortedRows.sort((a, b) =>
          sortTime(a[columnKey] as string, b[columnKey] as string, direction)
        );
      default:
        return newSortedRows;
    }
  }, [programs.sortedColumns, programs.data]);

  const rows = useMemo(() => sortRows(), [sortRows]);

  const calcWidth = (text: string, subtitle: string) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (context) {
      context.font =
        subtitle.length > text.length + 3
          ? 'normal normal normal 12px/16px "Globo", sans-serif'
          : 'normal normal bold 15px/20px "Globo", sans-serif';

      const { width } = context.measureText(
        subtitle.length > text.length + 3 ? subtitle : text
      );

      return Math.ceil(width) + 50;
    }

    return subtitle.length > text.length
      ? (subtitle.length + 1) * 10
      : (text.length + 1) * 11;
  };

  const generateColumnsDaysOfWeek = useCallback(
    (
      target: string,
      indexTarget: number,
      field: 'audience' | 'cpp' | 'cpm' | 'affinity' | 'impacts',
      formatter: 'percentage' | 'number' | 'currency',
      hideColumns: boolean,
      fractionDigits = 0
    ): readonly GridColumn<Program>[] => {
      const siglaFields = {
        audience: 'aud',
        cpp: 'cpp',
        cpm: 'cpm',
        affinity: 'afinidade',
        impacts: 'impactos'
      };
      const formattersText = { percentage: '%', number: '', currency: 'R$' };

      const exportXlsxFormat = `0.${Array.from({ length: fractionDigits })
        .fill(0)
        .join('')}%`;

      return weekDaysEN.map((weekDay) => ({
        key: `metricsPerTarget[${indexTarget}].metricsByWeekDay[${weekDay}][${field}]`,
        name: `${returTranslatedWeekDay(weekDay)} (${siglaFields[field]}${
          formattersText[formatter]
        }) (${target})`,
        sortable: true,
        width: calcWidth(
          `${returTranslatedWeekDay(weekDay)} (${siglaFields[field]}${
            formattersText[formatter]
          })`,
          target
        ),
        hideColumn: hideColumns,
        headerRenderer: (props) =>
          GridPaginationHeader({
            alignment: 'right',
            title: `${returTranslatedWeekDay(weekDay)} (${siglaFields[field]}${
              formattersText[formatter]
            })`,
            subtitle: target,
            dataMaskSubtitle: true,
            ...props
          }),
        exportXlsxFormat:
          formatter === 'percentage' ? exportXlsxFormat : undefined,
        formatter: ({ row }) => {
          const { metricsByWeekDay } = row.metricsPerTarget[indexTarget];
          const metric = metricsByWeekDay[weekDay];
          if (metric) {
            return (
              <span>
                {formatter === 'percentage' &&
                  formatterPercent(metric[field], { fractionDigits })}
                {formatter === 'number' &&
                  formatterNumber(metric[field], { fractionDigits })}
                {formatter === 'currency' &&
                  formatterCurrency(metric[field], { fractionDigits })}
              </span>
            );
          }
          return <span>-</span>;
        },
        headerCellClass: `${
          weekDay !== 'sunday' && 'grid-col__separate--none'
        }`,
        cellClass: (row: Program) =>
          `${
            weekDay !== 'sunday' ? 'grid-col__separate--none' : ''
          } ${classMaskOrUnmask(false)} ${
            hasProp(
              row.metricsPerTarget[indexTarget].metricsByWeekDay,
              `${weekDay}.${field}`
            )
              ? 'text-right'
              : 'text-center'
          }`
      }));
    },
    []
  );

  const generateColumnsPerTarget = useCallback(
    (target: string, indexTarget: number): readonly GridColumn<Program>[] => {
      return [
        {
          key: `metricsPerTarget[${indexTarget}].avgAudiencePercent`,
          name: `Média Aud% (${target})`,
          headerRenderer: (props) =>
            GridPaginationHeader({
              alignment: 'right',
              title: 'Méd. Aud%',
              subtitle: target,
              dataMaskSubtitle: true,
              classes: { title: 'plim-violet' },
              ...props
            }),
          exportXlsxFormat: '0.00000%',
          formatter: ({ row }) => {
            const { avgAudiencePercent } = row.metricsPerTarget[indexTarget];
            return (
              <span className="plim-violet">
                {formatterPercent(avgAudiencePercent || 0, {
                  fractionDigits: 5
                })}
              </span>
            );
          },
          cellClass: `${classMaskOrUnmask(false)} text-right ${
            !programs.hideDaysOfWeek ? 'grid-col__separate--none' : undefined
          }`,
          headerCellClass: !programs.hideDaysOfWeek
            ? 'grid-col__separate--none'
            : undefined,
          width: calcWidth('Méd. Aud%', target),
          hideColumn: false,
          sortable: true
        },
        ...generateColumnsDaysOfWeek(
          target,
          indexTarget,
          'audience',
          'percentage',
          programs.hideDaysOfWeek,
          5
        ),
        {
          key: `metricsPerTarget[${indexTarget}].share`,
          name: `Méd. Share (${target})`,
          hideColumn: getCurrentFilters()?.data?.typeTV === TypeTV.CLOSED, // Pedido do time de IM para ocultar share para pay tv
          headerRenderer: (props) =>
            GridPaginationHeader({
              alignment: 'right',
              title: 'Méd. Share',
              subtitle: target,
              dataMaskSubtitle: true,
              classes: { title: 'plim-violet' },
              ...props
            }),
          formatter: ({ row }) => {
            const { share } = row.metricsPerTarget[indexTarget];
            return (
              <span className="plim-violet">
                {formatterNumber(share ?? 0, { fractionDigits: 5 })}
              </span>
            );
          },
          cellClass: `${classMaskOrUnmask(false)} text-right`,
          sortable: true,
          width: calcWidth('Méd. Share', target)
        },
        {
          key: `metricsPerTarget[${indexTarget}].avgImpacts`,
          name: `Méd. Impactos (${target})`,
          headerRenderer: (props) =>
            GridPaginationHeader({
              alignment: 'right',
              title: 'Méd. Impactos',
              subtitle: target,
              dataMaskSubtitle: true,
              classes: { title: 'plim-violet' },
              ...props
            }),
          formatter: ({ row }) => {
            const { avgImpacts } = row.metricsPerTarget[indexTarget];
            return (
              <span className="plim-violet">
                {formatterNumber(avgImpacts ?? 0, { fractionDigits: 0 })}
              </span>
            );
          },
          headerCellClass: !programs.hideDaysOfWeek
            ? 'grid-col__separate--none'
            : undefined,
          cellClass: `${classMaskOrUnmask(false)} text-right ${
            !programs.hideDaysOfWeek ? 'grid-col__separate--none' : undefined
          }`,
          sortable: true,
          hideColumn: false,
          width: calcWidth('Méd. Impactos', target)
        },
        ...generateColumnsDaysOfWeek(
          target,
          indexTarget,
          'impacts',
          'number',
          programs.hideDaysOfWeek
        ),
        {
          key: `metricsPerTarget[${indexTarget}].affinity`,
          name: `Méd. Afinidade (${target})`,
          headerRenderer: (props) =>
            GridPaginationHeader({
              alignment: 'right',
              title: 'Méd. Afinidade',
              classes: { title: 'plim-violet' },
              subtitle: target,
              dataMaskSubtitle: true,
              ...props
            }),
          formatter: ({ row }) => {
            const { affinity } = row.metricsPerTarget[indexTarget];
            return (
              <span className="plim-violet">
                {formatterNumber(affinity ?? 0, { fractionDigits: 0 })}
              </span>
            );
          },
          headerCellClass: !programs.hideDaysOfWeek
            ? 'grid-col__separate--none'
            : undefined,
          cellClass: `${classMaskOrUnmask(false)} text-right ${
            !programs.hideDaysOfWeek ? 'grid-col__separate--none' : undefined
          }`,
          sortable: true,
          hideColumn: false,
          width: calcWidth('Méd. Afinidade', target)
        },
        ...generateColumnsDaysOfWeek(
          target,
          indexTarget,
          'affinity',
          'number',
          programs.hideDaysOfWeek,
          0
        ),
        {
          key: `metricsPerTarget[${indexTarget}].avgCpp`,
          name: `Média CPP (${target})`,
          headerRenderer: (props) =>
            GridPaginationHeader({
              alignment: 'right',
              title: 'Méd. CPP',
              subtitle: target,
              dataMaskSubtitle: true,
              classes: { title: 'plim-violet' },
              ...props
            }),
          formatter: ({ row }) => {
            const { avgCpp } = row.metricsPerTarget[indexTarget];
            return (
              <span className="plim-violet">
                {formatterNumber(avgCpp ?? 0, { fractionDigits: 1 })}
              </span>
            );
          },
          headerCellClass: !programs.hideDaysOfWeek
            ? 'grid-col__separate--none'
            : undefined,
          cellClass: `${classMaskOrUnmask(false)} text-right ${
            !programs.hideDaysOfWeek ? 'grid-col__separate--none' : undefined
          }`,
          sortable: true,
          hideColumn: false,
          width: calcWidth('Méd. CPP', target)
        },
        ...generateColumnsDaysOfWeek(
          target,
          indexTarget,
          'cpp',
          'number',
          programs.hideDaysOfWeek,
          2
        ),
        {
          key: `metricsPerTarget[${indexTarget}].avgCpm`,
          name: `Média CPM (${target})`,
          headerRenderer: (props) =>
            GridPaginationHeader({
              alignment: 'right',
              title: 'Méd. CPM',
              subtitle: target,
              dataMaskSubtitle: true,
              classes: { title: 'plim-violet' },
              ...props
            }),
          formatter: ({ row }) => {
            const { avgCpm } = row.metricsPerTarget[indexTarget];
            return (
              <span className="plim-violet">
                {formatterNumber(avgCpm ?? 0, { fractionDigits: 1 })}
              </span>
            );
          },
          headerCellClass: !programs.hideDaysOfWeek
            ? 'grid-col__separate--none'
            : undefined,
          cellClass: `${classMaskOrUnmask(false)} text-right ${
            !programs.hideDaysOfWeek ? 'grid-col__separate--none' : undefined
          }`,
          sortable: true,
          hideColumn: false,
          width: calcWidth('Méd. CPM', target)
        },
        ...generateColumnsDaysOfWeek(
          target,
          indexTarget,
          'cpm',
          'number',
          programs.hideDaysOfWeek,
          2
        )
      ];
    },
    [programs, generateColumnsDaysOfWeek, getCurrentFilters]
  );

  const generateMontlyPriceColumns = (
    priceByDate: Date,
    indexMontly: number
  ): GridColumn<Program> => {
    return {
      key: `pricesByDate[${indexMontly}].price`,
      name: `Valor Tabela R$ (${format(priceByDate, 'MM/yyyy')})`,
      headerRenderer: (props) =>
        GridPaginationHeader({
          alignment: 'right',
          title: `Valor Tabela R$`,
          subtitle: `${format(priceByDate, 'MM/yyyy')}`,
          ...props
        }),
      formatter: ({ row }) => {
        const price =
          row.pricesByDate[indexMontly] && row.pricesByDate[indexMontly].price;
        return <>{formatterNumber(price ?? 0, { fractionDigits: 1 })}</>;
      },
      cellClass: `${classMaskOrUnmask(false)} text-right`,
      sortable: true,
      width: 150
    };
  };

  const columns = useMemo((): readonly GridColumn<Program>[] => {
    const currentFilters = getCurrentFilters();

    const avgsColumns =
      currentFilters?.data?.targets.flatMap(generateColumnsPerTarget) || [];

    const monthlyPriceColumn = competenciesPrice.flatMap(
      generateMontlyPriceColumns
    );

    const typeTV = currentFilters?.data?.typeTV;

    return [
      {
        key: 'action',
        name: 'Ações',
        frozen: mediaQueryUpMd,
        width: 65,
        hideColumn: false,
        minWidth: 65,
        formatter: ({ row }) => {
          const isAdded = planning.planningPrograms.some(
            isEqualProgramByChannelAndInitialsAndName(row)
          );

          return (
            <ActionColumn
              classes={{
                tagManager: isAdded
                  ? classesGTM.ranking.removePlanning
                  : classesGTM.ranking.addPlanning
              }}
              disabled={row.avgPrice === 0}
              icon={isAdded ? IconMinus : IconPlus}
              onClick={() => handleAddOrRemove(row)}
            />
          );
        },
        cellClass: `${classMaskOrUnmask(false)} action_column__container`,
        headerCellClass: classMaskOrUnmask(false)
      },
      {
        key: 'channel',
        name: 'Canal',
        width: 150,
        frozen: mediaQueryUpMd,
        sortable: true,
        resizable: true,
        cellClass: `${classMaskOrUnmask(true)} grid-col__separate--none`,
        headerCellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`
      },
      {
        key: 'initials',
        name: 'Sigla',
        sortable: true,
        frozen: mediaQueryUpMd,
        headerCellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
        cellClass: (row: Program) =>
          `${
            row.initials
              ? 'grid-col__separate--none'
              : 'grid-col__separate--none text-center'
          } ${classMaskOrUnmask(false)}`,
        formatter: ({ row }) => <>{row?.initials ? row.initials : '-'}</>
      },
      {
        key: 'name',
        name: 'Programa',
        sortable: true,
        width: 300,
        frozen: mediaQueryUpLg,
        hideColumn: false,
        cellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
        headerCellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
        formatter: ({ row }) => (
          <>
            {typeTV === TypeTV.CLOSED ||
            row?.origem === OpenTVAudienceOrigin.PROGRAMA
              ? `${row?.name}`
              : `${row?.name} *`}
          </>
        )
      },
      {
        key: 'avgProgramStartTime',
        name: 'Horário Méd. (início)',
        sortable: true,
        headerRenderer: (props) => {
          return GridPaginationHeader({
            title: 'Horário Méd.',
            subtitle: 'início',
            ...props
          });
        },
        formatter: ({ row }) => (
          <>{row.avgDuration > 0 ? row.avgProgramStartTime : '-'}</>
        ),
        width: 120,
        hideColumn: false,
        cellClass: (row: Program) =>
          `${
            row.avgDuration
              ? 'grid-col__separate--none'
              : 'grid-col__separate--none text-center'
          } ${classMaskOrUnmask(false)}`,
        headerCellClass: 'grid-col__separate--none'
      },
      {
        key: 'avgProgramEndTime',
        name: 'Horário Méd. (fim)',
        sortable: true,
        headerRenderer: (props) =>
          GridPaginationHeader({
            title: 'Horário Méd.',
            subtitle: 'fim',
            ...props
          }),
        formatter: ({ row }) => (
          <>{row.avgDuration > 0 ? row.avgProgramEndTime : '-'}</>
        ),
        width: 120,
        hideColumn: false,
        cellClass: (row: Program) =>
          `${
            row.avgDuration
              ? 'grid-col__separate--none'
              : 'grid-col__separate--none text-center'
          } ${classMaskOrUnmask(false)}`,
        headerCellClass: 'grid-col__separate--none'
      },
      {
        key: 'avgDuration',
        name: 'Duração (min)',
        sortable: true,
        width: 90,
        hideColumn: false,
        formatter: ({ row }) => (
          <>
            {row.avgDuration > 0
              ? `${formatterNumber(row.avgDuration, { fractionDigits: 0 })}'`
              : '-'}
          </>
        ),
        headerRenderer: (props) =>
          GridPaginationHeader({
            alignment: 'right',
            title: 'Duração',
            subtitle: 'min',
            ...props
          }),
        cellClass: (row: Program) =>
          `${
            row.avgDuration
              ? 'grid-col__separate--none text-right'
              : 'grid-col__separate--none text-center'
          } ${classMaskOrUnmask(false)}`,
        headerCellClass: 'grid-col__separate--none'
      },
      {
        key: 'daysExhibition',
        name: 'Dias',
        sortable: true,
        width: 150,
        hideColumn: false,
        cellClass: classMaskOrUnmask(false),
        headerRenderer: (props) =>
          GridPaginationHeader({
            title: 'Dias',
            ...props
          }),
        formatter: ({ row }) => (
          <>{formatterDaysExhibition(row.daysExhibition)}</>
        )
      },
      ...monthlyPriceColumn,
      ...avgsColumns
    ];
  }, [
    handleAddOrRemove,
    planning.planningPrograms,
    generateColumnsPerTarget,
    mediaQueryUpLg,
    mediaQueryUpMd,
    getCurrentFilters,
    competenciesPrice
  ]);

  return {
    rows,
    onSortColumnsChange,
    columns
  };
};

export default useRanking;
