import { SortColumn } from 'react-data-grid';
import { ApiErrorResponse } from '../../../services/shared/Api.service';
import {
  ProgramsTypes,
  ProgramAction,
  Program,
  ProgramsState
} from './Programs.types';
import getPrograms from '../../../services/MediaSimulator/Programs.service';
import { AppThunk } from '../..';

const ActionSetHideDaysOfWeekRanking = (
  hideDaysOfWeek: boolean,
  sceneryId: string
): ProgramAction => ({
  type: ProgramsTypes.SET_HIDE_DAYS_OF_WEEK,
  payload: {
    hideDaysOfWeek,
    sceneryId
  }
});

const ActionLoadProgramsSuccess = (programs: Program[], sceneryId: string) => {
  const payload: ProgramAction = {
    payload: {
      rows: programs,
      count: programs.length,
      sceneryId
    },
    type: ProgramsTypes.LOAD_PROGRAMS_SUCCESS
  };

  return payload;
};

const ActionClearPrograms = (sceneryId: string): ProgramAction => ({
  type: ProgramsTypes.CLEAR_PROGRAMS,
  payload: { sceneryId }
});

export const SetHideDaysOfWeekRanking =
  (hideDaysOfWeek: boolean): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      ActionSetHideDaysOfWeekRanking(
        hideDaysOfWeek,
        getState().simulation.currentSceneryId
      )
    );
  };

export const LoadProgramsFailure = (error: string, sceneryId: string) => ({
  type: ProgramsTypes.LOAD_PROGRAMS_ERROR,
  payload: {
    message: error,
    sceneryId
  }
});

export const LoadProgramsSuccess =
  (programs: Program[], sceneryId: string): AppThunk =>
  async (dispatch) => {
    dispatch(ActionLoadProgramsSuccess(programs, sceneryId));
  };

export const ClearPrograms = (): AppThunk => async (dispatch, getState) => {
  dispatch(ActionClearPrograms(getState().simulation.currentSceneryId));
};

export const LoadingProgramsRequest = (sceneryId: string) => ({
  type: ProgramsTypes.LOAD_PROGRAMS_REQUEST,
  payload: { sceneryId }
});

export const GetProgramsByScenery =
  (sceneryId: string): AppThunk =>
  async (dispatch, getState) => {
    const { filters, simulation } = getState();
    try {
      dispatch(LoadingProgramsRequest(sceneryId));
      const formData = filters.forms.find((f) => f.sceneryId === sceneryId);

      if (!formData?.data) throw new Error('Filtros não preenchidos');

      const allPrograms = await getPrograms(
        formData.data,
        simulation.me_filial_agencia as string
      );

      dispatch(LoadProgramsSuccess(allPrograms, sceneryId));
    } catch (error) {
      const message =
        error instanceof Error
          ? error.message
          : (error as ApiErrorResponse)?.msg ||
            'Ops, houve um problema ao realizar a busca.';

      dispatch(LoadProgramsFailure(message, sceneryId));
    }
  };

// FEATURE COPIA
const CopyProgramsSuccess = (response: ProgramsState, sceneryId: string) => {
  const payload: ProgramAction = {
    payload: {
      rows: response.data,
      count: response.data.length,
      message: '',
      sceneryId
    },
    type: ProgramsTypes.LOAD_PROGRAMS_SUCCESS
  };

  return payload;
};

// FEATURE COPIA
export const CopyPrograms =
  (programs: ProgramsState, sceneryId: string): AppThunk =>
  async (dispatch, getState) => {
    const { simulation, filters } = getState();
    try {
      dispatch(LoadingProgramsRequest(simulation.currentSceneryId));
      const formData = filters.forms.find(
        (f) => f.sceneryId === simulation.currentSceneryId
      );
      if (!formData?.data) throw new Error('Filtros não preenchidos');

      dispatch(CopyProgramsSuccess(programs, sceneryId));
    } catch (error) {
      const message =
        error instanceof Error
          ? error.message
          : (error as ApiErrorResponse)?.msg ||
            'Ops, houve um problema ao realizar a busca.';

      dispatch(LoadProgramsFailure(message, simulation.currentSceneryId));
    }
  };

const GetPrograms = (): AppThunk => async (dispatch, getState) => {
  const { simulation } = getState();
  dispatch(GetProgramsByScenery(simulation.currentSceneryId));
};

export const RemoveProgramsScenery = (sceneryId: string): ProgramAction => ({
  type: ProgramsTypes.REMOVE_PROGRAMS_SCENERY,
  payload: { sceneryId }
});

export const ResetPrograms = () => ({
  type: ProgramsTypes.RESET_RANKING
});

export const SetProgramsSortedColumns =
  (sortedColumns: SortColumn[]): AppThunk =>
  async (dispatch, getState) => {
    const action: ProgramAction = {
      type: ProgramsTypes.SET_PROGRAMS_SORTED_COLUMNS,
      payload: {
        sceneryId: getState().simulation.currentSceneryId,
        sortedColumns
      }
    };
    dispatch(action);
  };

export default GetPrograms;
