import React, { useMemo } from 'react';
import { Stack } from '@mui/material';

import PanelContainer from '../../../../../Competitive/PanelContainer';
import LineChartSingular from '../../../../Charts/LineChart/LineChartSingular';
import { ICommonsFilter, TypePeriod } from '../types';
import { OptionsList } from '../../../../../Form/types';
import AudiencebyChannelsRadiographyFilter from './AudienceOpenTvRegularPlazasChannelsRadiographyFilter';
import InfoCard from '../../../../Cards/InfoCard';
import AlertInfoCard from '../../../../Cards/AlertInfoCard';
import BarChartsCard from '../../../../Charts/ChartsCard/BarChartsCard';
import {
  formatterNumber,
  formatterNumberShort
} from '../../../../../../helpers/Formatters';
import DonutChart from '../../../../Charts/DonutChart';
import { useAppMediaQuery, useAppSelector } from '../../../../../../hooks';
import useAudienceData from './useAudienceOpenTvRegularPlazasChannelsRadiographyData';
import Loading from '../../../../../Loading';
import KantarDescription from '../../../../KantarDescription/KantarDescription';

const AudienceByChannelsRadiography: React.FC<ICommonsFilter> = ({
  target,
  timeRange,
  weekDays,
  programmingGenders,
  newFilter,
  setNewFilter
}) => {
  const matches = useAppMediaQuery('lg');
  const direction = useMemo(() => (!matches ? 'column' : 'row'), [matches]);
  const { audience } = useAppSelector((state) => state);
  const [plaza, setPlaza] = React.useState<OptionsList | undefined>();
  const [channel, setChannel] = React.useState<OptionsList | undefined>();
  const [period, setPeriod] = React.useState<TypePeriod | undefined>();
  const {
    audienceAvarageDataByWeekdays,
    audienceAvarageDataByGenders,
    audienceAvarageDataByAges,
    audienceAvarageDataByFinancialClasses,
    audienceAvarageDataByTimeRanges,
    audienceAvarageDataByProgrammingGenders
  } = useAudienceData({
    target,
    timeRange,
    weekDays,
    programmingGenders,
    channel,
    plaza,
    period,
    setChannel,
    setPlaza,
    setPeriod
  });

  if (
    !audience?.openTv?.byChannels?.avarageDatas?.data?.length &&
    !audience?.openTv?.byChannels?.avarageAudienceByWeekdays?.data?.length &&
    !audience?.openTv?.byChannels?.avarageAudienceByGenders?.data?.length &&
    !audience?.openTv?.byChannels?.avarageAudienceByAges?.data?.length &&
    !audience?.openTv?.byChannels?.avarageAudienceByFinancialClasses?.data
      ?.length &&
    !audience?.openTv?.byChannels?.avarageAudienceByTimeRanges?.data?.length &&
    !audience?.openTv?.byChannels?.avarageAudienceByProgrammingGenders?.data
      ?.length &&
    !audience?.openTv?.byChannels?.avarageAudienceByProgrammingGenders?.error &&
    !audience?.openTv?.byChannels?.avarageAudienceByTimeRanges?.error &&
    !audience?.openTv?.byChannels?.avarageAudienceByFinancialClasses?.error &&
    !audience?.openTv?.byChannels?.avarageAudienceByAges?.error &&
    !audience?.openTv?.byChannels?.avarageAudienceByGenders?.error &&
    !audience?.openTv?.byChannels?.avarageAudienceByWeekdays?.error &&
    !audience?.openTv?.byChannels?.avarageDatas?.error
  ) {
    return (
      <PanelContainer className="panel-container__flex panel-container__border-all panel-container__margin-bottom">
        <AudiencebyChannelsRadiographyFilter
          {...{
            channel,
            plaza,
            period,
            setChannel,
            setPlaza,
            setPeriod,
            target,
            timeRange,
            weekDays,
            programmingGenders,
            newFilter,
            setNewFilter
          }}
        />
        <Loading
          classes={{
            container:
              'loading-container__relative loading-container__min-height'
          }}
        />
      </PanelContainer>
    );
  }

  if (
    audience?.openTv?.byChannels?.avarageDatas.loading &&
    audience?.openTv?.byChannels?.avarageAudienceByWeekdays.loading &&
    audience?.openTv?.byChannels?.avarageAudienceByGenders.loading &&
    audience?.openTv?.byChannels?.avarageAudienceByAges.loading &&
    audience?.openTv?.byChannels?.avarageAudienceByFinancialClasses.loading &&
    audience?.openTv?.byChannels?.avarageAudienceByTimeRanges.loading &&
    audience?.openTv?.byChannels?.avarageAudienceByProgrammingGenders.loading
  ) {
    return (
      <PanelContainer className="panel-container__flex panel-container__border-all panel-container__margin-bottom">
        <AudiencebyChannelsRadiographyFilter
          {...{
            channel,
            plaza,
            period,
            setChannel,
            setPlaza,
            setPeriod,
            target,
            timeRange,
            weekDays,
            programmingGenders,
            newFilter,
            setNewFilter
          }}
        />
        <Loading
          classes={{
            container:
              'loading-container__relative loading-container__min-height'
          }}
        />
      </PanelContainer>
    );
  }

  const isCurrentYearSelected = () => {
    return `${new Date().getFullYear()}` === `${period?.label}`;
  };

  return (
    <PanelContainer className="panel-container__flex panel-container__border-all panel-container__margin-bottom">
      <AudiencebyChannelsRadiographyFilter
        {...{
          channel,
          plaza,
          period,
          setChannel,
          setPlaza,
          setPeriod,
          target,
          timeRange,
          weekDays,
          programmingGenders,
          newFilter,
          setNewFilter
        }}
      />
      <>
        {isCurrentYearSelected() && (
          <AlertInfoCard text="O período selecionado exibe dados acumulados do ano até o último mês completo." />
        )}
      </>
      <Stack
        alignItems="center"
        justifyContent="space-around"
        columnGap={2}
        rowGap={4}
        direction="row"
        flexWrap="wrap"
        gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
      >
        <InfoCard
          content={`${formatterNumber(
            audience?.openTv?.byChannels?.avarageDatas?.data?.[0]
              ?.pc_audiencia ?? 0,
            { fractionDigits: 1 }
          )}`}
          header="Audiência média"
          description={target?.value}
          metric=" %"
          opacity={1}
          color="#FFF2F8"
          style={{
            gridColumn: 'span 2'
          }}
          loading={audience?.openTv?.byChannels?.avarageDatas.loading}
        />
        <InfoCard
          content={`${formatterNumber(
            audience?.openTv?.byChannels?.avarageDatas?.data?.[0]
              ?.pc_share_tle ?? 0,
            { fractionDigits: 1 }
          )}`}
          header="Share"
          description={target?.value}
          metric=" %"
          opacity={1}
          color="#FFF2F8"
          style={{
            gridColumn: 'span 2'
          }}
          loading={audience?.openTv?.byChannels?.avarageDatas.loading}
        />
        <InfoCard
          content={`${formatterNumberShort(
            audience?.openTv?.byChannels?.avarageDatas?.data?.[0]
              ?.vl_audiencia_abs,
            true
          )}`}
          header="Alcance médio por minuto"
          description={target?.value}
          metric={` ${formatterNumberShort(
            audience?.openTv?.byChannels?.avarageDatas?.data?.[0]
              ?.vl_audiencia_abs,
            false,
            true
          )}`}
          opacity={1}
          color="#FFF2F8"
          style={{
            gridColumn: 'span 2'
          }}
          loading={audience?.openTv?.byChannels?.avarageDatas.loading}
        />
        {/* <InfoCard
          hidden
          content={`${formatterNumberShort(
            audience?.openTv?.byChannels?.avarageDatas?.data?.[0]
              ?.vl_alcance_abs,
            true
          )}`}
          header="Alcance médio diário"
          metric={`${formatterNumberShort(
            audience?.openTv?.byChannels?.avarageDatas?.data?.[0]
              ?.vl_alcance_abs,
            false,
            true
          )}`}
          description={target?.value}
          opacity={1}
          color="rgba(5, 166, 255, 0.1)"
          style={{
            gridColumn: 'span 2'
          }}
          loading={audience?.openTv?.byChannels?.avarageDatas.loading}
        />
        <InfoCard
          hidden
          content={
            audience?.openTv?.byChannels?.avarageDatas?.data?.[0]?.hr_ats ?? 0
          }
          header="Tempo médio por exibição"
          description={target?.value}
          opacity={1}
          metric=" Horas"
          color="rgba(5, 166, 255, 0.1)"
          style={{
            gridColumn: 'span 2'
          }}
          loading={audience?.openTv?.byChannels?.avarageDatas.loading}
        /> */}
      </Stack>
      {audienceAvarageDataByWeekdays?.length ? (
        <LineChartSingular
          header={`Audiência média por dia da semana `}
          data={[
            {
              id: 'Audiência',
              color: '#5D43FA',
              data: audienceAvarageDataByWeekdays ?? []
            }
          ]}
          formatter={(val) => `${formatterNumber(val, { fractionDigits: 1 })}%`}
        />
      ) : null}
      <Stack
        alignItems="center"
        columnGap={2}
        direction={direction}
        rowGap={4}
        spacing={1}
        flexWrap="nowrap"
      >
        <Stack width={matches ? '33%' : '100%'}>
          <DonutChart data={audienceAvarageDataByGenders} header="Gênero" />
        </Stack>
        <Stack width={matches ? '33%' : '100%'}>
          <BarChartsCard
            dataComplement="% do total"
            header="Idade"
            type="collumn"
            rawData={audienceAvarageDataByAges}
            padding={0.07}
            formatter={(val) => formatterNumber(val, { fractionDigits: 1 })}
            valueFormat={(val) =>
              `${formatterNumber(val, { fractionDigits: 1 })}%`
            }
          />
        </Stack>
        <Stack width={matches ? '33%' : '100%'}>
          <DonutChart
            data={audienceAvarageDataByFinancialClasses}
            header="Classe"
          />
        </Stack>
      </Stack>
      <BarChartsCard
        dataComplement="audiência"
        header={`Audiência média das faixas horárias (${target?.value})`}
        type="collumn"
        height="420px"
        rawData={audienceAvarageDataByTimeRanges}
        formatter={(val) => `${formatterNumber(val, { fractionDigits: 1 })}%`}
        valueFormat={(val) => `${formatterNumber(val, { fractionDigits: 1 })}%`}
        padding={1}
      />
      <BarChartsCard
        dataComplement="audiência"
        header={`Audiência média por gênero (${target?.value})`}
        type="horizontal-bar"
        height="900px"
        rawData={audienceAvarageDataByProgrammingGenders}
        formatter={(val) => `${formatterNumber(val, { fractionDigits: 1 })}%`}
        valueFormat={(val) => `${formatterNumber(val, { fractionDigits: 1 })}%`}
        padding={0.03}
      />
      <KantarDescription />
    </PanelContainer>
  );
};

export default AudienceByChannelsRadiography;
