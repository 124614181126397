import { useState } from 'react';
import { PointTooltipProps, ResponsiveLine } from '@nivo/line';
import { LineChartData } from './types';
import MetricCard from '../MetricCard';
import getDefaultColor from '../../../../helpers/Colors';

interface Props {
  data: LineChartData[];
  header: string;
  formatter?: (value: any) => string;
}

const EncurtedIds: { [key: string]: string } = {
  'TOTAL LI...': 'TOTAL LIGADOS'
};

const customTooltip = ({ point }: PointTooltipProps, formatter?: any) => {
  return (
    <div className="collumn-chart__tooltip">
      <div className="collumn-chart__tooltip__title">
        {EncurtedIds?.[point?.serieId] ?? point?.serieId ?? point?.id}
      </div>
      <div className="collumn-chart__tooltip__text">{`${point.data.x}: ${
        formatter
          ? formatter(Number(point.data.y))?.replace('.', ',')
          : `${point.data.y}`
      }`}</div>
    </div>
  );
};

const formatLegends = (
  data: LineChartData[],
  itemsPerLegend: number,
  activeIds: string[],
  setActiveIds: (ids: string[]) => void
) => {
  const slicesMax = Math.ceil(data.length / itemsPerLegend);

  const slices = Array.from({ length: slicesMax }, (_, index) => {
    return data.slice(index * itemsPerLegend, (index + 1) * itemsPerLegend);
  });

  const legends = slices.map((slice, index) => {
    return {
      anchor: 'bottom',
      direction: 'row',
      justify: false,
      translateX: 0,
      translateY: 60 + index * 40,
      itemsSpacing: 10,
      itemDirection: 'left-to-right',
      itemWidth: 130,
      itemHeight: 20,
      itemOpacity: 1,
      symbolSize: 14,
      symbolShape: 'circle',
      symbolBorderColor: 'rgba(0, 0, 0, .5)',
      effects: [
        {
          on: 'hover',
          style: {
            itemBackground: 'rgba(0, 0, 0, .03)',
            itemOpacity: 1
          }
        }
      ],
      onClick: (item: any) => {
        if (activeIds.includes(item.id)) {
          setActiveIds(activeIds.filter((id) => id !== item.id));
        } else {
          setActiveIds([...activeIds, item.id]);
        }
      },
      data: slice?.map((item) => ({
        ...item,
        label: item?.id?.length > 15 ? `${item?.id?.slice(0, 15)}...` : item?.id
      }))
    };
  });

  const updatedLegends: any = [];

  legends.forEach((legend) => {
    if (legend.data.find((item) => activeIds.includes(item.id))) {
      legend.data.forEach((item, index) => {
        if (activeIds.includes(item.id)) {
          updatedLegends.push({
            ...legend,
            itemBackground: `${item.color}10`,
            translateX:
              index * (legend.itemWidth + legend.itemsSpacing) -
              ((legend.data.length - 1) / 2) *
                (legend.itemWidth + legend.itemsSpacing),
            data: [item]
          });
        } else {
          updatedLegends.push({
            ...legend,
            translateX:
              index * (legend.itemWidth + legend.itemsSpacing) -
              ((legend.data.length - 1) / 2) *
                (legend.itemWidth + legend.itemsSpacing),
            data: [item]
          });
        }
      });
    } else {
      updatedLegends.push(legend);
    }
  });

  return updatedLegends;
};

export default function LineChartByPayTvChannels({
  data,
  header,
  formatter
}: Props) {
  const reversedData = [...data].reverse();
  const [activeIds, setActiveIds] = useState<string[]>([]);
  const itemsPerLegend = 6;
  const height = 600 + 40 * Math.ceil(data.length / itemsPerLegend);

  return (
    <MetricCard
      classes={{ content: 'fill-height d-flex flex-column align-center' }}
      header={header}
      height={height}
    >
      <ResponsiveLine
        data={
          activeIds.length
            ? reversedData.filter((e) => activeIds.includes(e.id))
            : reversedData
        }
        margin={{
          top: 50,
          right: 110,
          bottom: 48 * Math.ceil(data.length / itemsPerLegend) + 40,
          left: 60
        }}
        xScale={{ type: 'point' }}
        colors={({ id }) => getDefaultColor(id, 'paytv')}
        crosshairType="bottom"
        enableGridY
        gridYValues={5}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle',
          truncateTickAt: 0
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: -40,
          legendPosition: 'middle',
          truncateTickAt: 0,
          format: (value) => `${formatter ? formatter(value) : value}`
        }}
        tooltip={(point) => customTooltip(point, formatter)}
        pointSize={10}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'color', modifiers: [] }}
        pointLabel={(point) =>
          formatter ? formatter(point.data.y) : String(point.data.y)
        }
        pointLabelYOffset={-12}
        enableTouchCrosshair
        useMesh
        enableGridX={false}
        theme={{
          axis: {
            ticks: {
              text: {
                fontSize: 10,
                fontFamily: 'inherit',
                fontWeight: 600,
                fill: '#000000'
              }
            }
          },
          legends: {
            text: {
              fontSize: 14,
              fontFamily: 'inherit',
              fontWeight: 400,
              fill: '#000000'
            }
          },
          grid: {
            line: {
              stroke: '#e0e0e0',
              strokeDasharray: '4 4'
            }
          }
        }}
        legends={
          formatLegends(data, itemsPerLegend, activeIds, setActiveIds) as any
        }
      />
    </MetricCard>
  );
}

LineChartByPayTvChannels.defaultProps = {
  formatter: undefined
};
