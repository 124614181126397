export const calculateImpressionsNumber = (
  investimentValue: number | undefined,
  cpm: number | undefined
) => {
  if (investimentValue !== undefined && cpm !== undefined) {
    const impressions = Math.floor((+investimentValue / +cpm) * 1000);
    return impressions;
  }
  return 0;
};

export const calculateImpressionsToLocaleString = (
  investimentValue: number | undefined,
  cpm: number
) => {
  if (investimentValue !== undefined && +investimentValue >= +cpm) {
    const impressions = calculateImpressionsNumber(investimentValue, cpm);
    return impressions.toLocaleString('pt-BR');
  }
  return '-';
};
