import './Univers.scss';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useCallback, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useCountUp } from 'react-countup';
import {
  formatterCurrency,
  formatterNumber,
  formatterPercent
} from '../../helpers/Formatters';
import { calcTotalizers } from '../../helpers/CalcsSimulation';
import usePlanningRedux from '../Planning/usePlanningRedux';
import paths from '../../routes/paths';
import { useAppSelector, useAppDispatch, useAppMediaQuery } from '../../hooks';
import { SetTotalizers } from '../../store/modules/Planning/Planning.actions';

const Univers = () => {
  const downLg = useAppMediaQuery('lg', 'down');
  const dispatch = useAppDispatch();
  const planning = usePlanningRedux().currentPlanning;
  const refTimeout = useRef<NodeJS.Timeout | string | number | undefined>();
  const { simulation } = useAppSelector((state) => state);
  const mountScenery = simulation.currentScenery;
  const mountSceneryId = simulation.currentSceneryId;
  const { path, childrens } = paths.home.childrens.simulation;
  const pathPlanning = `${path}/${mountScenery}/${childrens.planning}`;

  const callFormatterCurrency = useCallback(
    (val: number) => formatterCurrency(val, { fractionDigits: 1 }),
    []
  );
  const callFormatterNumber = useCallback(
    (val: number) => formatterNumber(val, { fractionDigits: 5 }),
    []
  );
  const callFormatterPercent = useCallback(
    (val: number) => formatterPercent(val, { fractionDigits: 3 }),
    []
  );

  const refSumNegotiated = useRef(null);
  const sumNegotiated = useCountUp({
    ref: refSumNegotiated,
    startVal: planning.totalNegotiated,
    end: planning.totalNegotiated,
    delay: 0,
    duration: 2,
    decimals: 2,
    formattingFn: callFormatterCurrency
  });

  const refDiscountAvg = useRef(null);
  const discountAvg = useCountUp({
    ref: refDiscountAvg,
    startVal: planning.avgDiscount,
    end: planning.avgDiscount,
    delay: 0,
    duration: 2,
    decimals: 3,
    formattingFn: callFormatterPercent
  });

  const refSumTotal = useRef(null);
  const sumTotal = useCountUp({
    ref: refSumTotal,
    startVal: planning.totalAmount,
    end: planning.totalAmount,
    delay: 0,
    duration: 2,
    decimals: 2,
    formattingFn: callFormatterCurrency
  });

  const refSumInsertions = useRef(null);
  const sumInsertions = useCountUp({
    ref: refSumInsertions,
    startVal: planning.totalInsertions,
    end: planning.totalInsertions,
    delay: 0,
    duration: 2
  });

  const refSumGrpOrTrp = useRef(null);
  const sumGrpOrTrp = useCountUp({
    ref: refSumGrpOrTrp,
    startVal: planning.totalGrpOrTrp,
    end: planning.totalGrpOrTrp,
    delay: 0,
    duration: 2,
    decimals: 5,
    formattingFn: callFormatterNumber
  });

  const sumNegotiatedHandles = useRef(sumNegotiated);
  const discountAvgHandles = useRef(discountAvg);
  const sumTotalHandles = useRef(sumTotal);
  const sumInsertionsHandles = useRef(sumInsertions);
  const sumGrpOrTrpHandles = useRef(sumGrpOrTrp);

  useEffect(() => {
    const {
      totalAmount,
      totalNegotiated,
      avgDiscount,
      totalInsertions,
      totalTrpOrGrp
    } = calcTotalizers(planning.planningPrograms, planning.target);

    sumNegotiatedHandles.current.update(totalNegotiated);

    sumTotalHandles.current.update(totalAmount);

    discountAvgHandles.current.update(avgDiscount);

    sumInsertionsHandles.current.update(totalInsertions);

    sumGrpOrTrpHandles.current.update(totalTrpOrGrp);

    dispatch(
      SetTotalizers(
        totalAmount,
        totalNegotiated,
        avgDiscount,
        totalInsertions,
        totalTrpOrGrp,
        mountSceneryId
      )
    );
  }, [planning.planningPrograms, dispatch, planning.target, mountSceneryId]);

  const [expanded, setExpanded] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);

  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.match(pathPlanning)) setVisible(true);
    else setVisible(false);
  }, [pathPlanning, location.pathname]);

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.match(pathPlanning)) {
      setExpanded(true);
      refTimeout.current = setTimeout(() => {
        setExpanded(false);
      }, 5000);
    }

    return () => {
      clearTimeout(refTimeout.current);
    };
  }, [pathPlanning, location.pathname]);

  return (
    <Box
      className="univers"
      sx={{
        marginTop: expanded ? '0px' : '60px',
        height: expanded ? '255px' : '80px',
        display: visible ? undefined : 'none'
      }}
    >
      <div className="univers_accordion">
        <AccordionSummary
          aria-controls="panel1bh-content"
          classes={{
            root: 'univers_accordion__summary',
            content: 'univers_accordion__content'
          }}
          id="panel1bh-header"
        >
          <div
            className={`d-flex justify-center ${
              downLg ? 'flex-column fill-width py-4' : ''
            }`}
          >
            <div className="d-flex justify-end">
              <div className="totalizers__stats__wrapper">
                <span className="totalizers__stats__wrapper--field">
                  Inserções:
                </span>
                <span
                  ref={refSumInsertions}
                  className="totalizers__stats__wrapper--value"
                />
              </div>
            </div>
            <div className="d-flex justify-end">
              <div className="totalizers__stats__wrapper">
                <span className="totalizers__stats__wrapper--field">
                  Valor total:
                </span>
                <span
                  ref={refSumTotal}
                  className="totalizers__stats__wrapper--value"
                />
              </div>
            </div>
            <div className="d-flex justify-end">
              <div className="totalizers__stats__wrapper">
                <span className="totalizers__stats__wrapper--field">
                  Desconto médio:
                </span>
                <span
                  ref={refDiscountAvg}
                  className="totalizers__stats__wrapper--value"
                />
              </div>
            </div>
            <div className="d-flex justify-end">
              <div className="totalizers__stats__wrapper">
                <span className="totalizers__stats__wrapper--field">
                  Valor negociado:
                </span>
                <span
                  ref={refSumNegotiated}
                  className="totalizers__stats__wrapper--value"
                />
              </div>
            </div>
            <div className="d-flex justify-end">
              <div className="totalizers__stats__wrapper">
                <span className="totalizers__stats__wrapper--field">
                  GRP/TRP:
                </span>
                <span
                  ref={refSumGrpOrTrp}
                  className="totalizers__stats__wrapper--value"
                />
              </div>
            </div>
          </div>
        </AccordionSummary>
      </div>
    </Box>
  );
};

export default Univers;
