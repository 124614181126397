import {
  MarketResponse,
  MarketsResponse
} from '../../store/modules/Markets/Markets.types';
import { TypeTV, apiV1 } from '../shared/Api.service';

const path = '/markets';

export default async function getMarkets(
  typeTV: TypeTV,
  agencyUf: string
): Promise<MarketsResponse> {
  const { data } = await apiV1.get<MarketsResponse>(path, {
    params: { typeTV, agencyUf }
  });
  return data;
}

export async function getNotebookMarket(
  marketSignal: string,
  agencyUf: string
): Promise<MarketResponse> {
  const { data } = await apiV1.get<MarketResponse>(`${path}/notebook`, {
    params: { signal: marketSignal, agencyUf }
  });

  return data;
}
