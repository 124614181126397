import './InvestimentInput.scss';
import React, { useEffect, useRef, useState } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import TextField from '@mui/material/TextField';
import { attributeMaskOrUnmask } from '../../../helpers/Utils';
import { CpmData } from '../../../store/modules/Digital/Cpm/Cpm.types';
import classesGTM from '../../../config/classesGTM';
import { calculateImpressionsNumber } from '../../../helpers/CalcsDigital';

interface InvestimentInputProps extends NumberFormatProps {
  cpmMaxValue: number;
  cpmMinValue: number;
  availableImpressions: number;
  name: string;
  investiment: number;
  onChangeValue: (value: number, impressions: number, groupId: number) => void;
  error: boolean;
  groupId: number;
  cpm: CpmData | undefined;
  changed: boolean | undefined;
}

const InvestimentInput = React.forwardRef(function InvestimentInput(
  props: InvestimentInputProps
) {
  const {
    decimalScale = 2,
    autoFocus = false,
    cpmMaxValue,
    cpmMinValue,
    investiment,
    availableImpressions,
    onChangeValue,
    error,
    groupId,
    cpm,
    changed
  } = props;
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  const [value, setValue] = useState<number | undefined>(undefined);
  const [helperText, setHelperText] = useState('');
  const [limitInvest, setLimitInvest] = useState('');
  const [exceedImpressions, setExceedImpressions] = useState(false);

  useEffect(() => {
    if (error && !cpm?.investimentValue) {
      setHelperText('Por favor, insira o valor do investimento.');
      setLimitInvest('');
    }
  }, [cpm?.investimentValue, error]);

  const handleBlur = async () => {
    if (!value) {
      setHelperText('Por favor, insira o valor do investimento.');
      setLimitInvest('');
      onChangeValue(0, 0, groupId);

      return;
    }

    const calculatedMaxImpressions = calculateImpressionsNumber(
      value,
      cpmMinValue
    );

    const calculatedImpressions = calculateImpressionsNumber(value, cpm?.cpm);

    if (value < cpmMaxValue) {
      setHelperText(
        'O valor de investimento deve ser igual ou maior o cpm de 30s.'
      );
      setLimitInvest('min');

      onChangeValue(value, calculatedImpressions, groupId);
      return;
    }

    if (calculatedMaxImpressions > availableImpressions) {
      setHelperText(' ');
      setLimitInvest('');

      setExceedImpressions(true);
      onChangeValue(value, calculatedImpressions, groupId);
      return;
    }
    setExceedImpressions(false);
    setHelperText('');
    setLimitInvest('');
    onChangeValue(value, calculatedImpressions, groupId);
  };

  useEffect(() => {
    if (changed) {
      setValue(undefined);
    } else if (investiment > 0) {
      setValue(investiment);
    }
  }, [investiment, changed]);

  const handleChange = (values: any) => {
    setValue(values.floatValue);

    setExceedImpressions(false);
    setHelperText('');
    setLimitInvest('');
  };

  const handleEnterPress = (event: any) => {
    if (event.key === 'Enter') {
      event.target.blur();
      handleBlur();
    }
  };

  return (
    <>
      <NumberFormat
        className={`investiment-input ${
          limitInvest
            ? classesGTM.simulation.digital.alertaInvestimentoMinimo
            : ''
        }`}
        customInput={TextField}
        label="Insira o investimento"
        autoComplete="off"
        autoFocus={autoFocus}
        decimalScale={decimalScale}
        decimalSeparator=","
        thousandSeparator="."
        prefix="R$ "
        onKeyDown={handleEnterPress}
        inputRef={inputRef}
        required
        onValueChange={handleChange}
        onBlur={handleBlur}
        error={!!helperText}
        helperText={helperText}
        value={value}
        onFocus={(e: any) => {
          e.target.setSelectionRange(2, 2);
        }}
        {...attributeMaskOrUnmask(false)}
      />
      {exceedImpressions && (
        <div
          className={`investiment-alert ${classesGTM.simulation.digital.alertaInvestimentoMaximo}`}
        >
          <h5>Ajuste sua segmentação</h5>
          <span>
            Seu investimento excede as impressões disponíveis. Amplie sua
            campanha para alcançar um público maior.
          </span>
        </div>
      )}
    </>
  );
});

export default InvestimentInput;
