import { toast } from 'react-toastify';
import { MarketAction, MarketsResponse, MarketsTypes } from './Markets.types';
import getMarkets from '../../../services/MediaSimulator/Markets.service';
import { TypeTV } from '../../../services/shared/Api.service';
import { AppThunk } from '../..';

const LoadMarketSuccess = (
  response: MarketsResponse,
  typeTV: TypeTV.OPEN | TypeTV.CLOSED
) => {
  const payload: MarketAction = {
    type: MarketsTypes.LOAD_MARKETS_SUCCESS,
    payload: {
      count: response.data.count,
      rows: response.data.rows,
      typeTV
    }
  };

  return payload;
};

const LoadMarketError = (
  error: string,
  typeTV: TypeTV.OPEN | TypeTV.CLOSED
) => {
  const payload: MarketAction = {
    type: MarketsTypes.LOAD_MARKETS_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error,
      typeTV
    }
  };

  return payload;
};

const LoadingMarkets = (typeTV: TypeTV.OPEN | TypeTV.CLOSED) => ({
  type: MarketsTypes.LOAD_MARKETS_REQUEST,
  payload: { typeTV }
});

const LoadMarketRequest =
  (typeTV: TypeTV.OPEN | TypeTV.CLOSED, agencyUf: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingMarkets(typeTV));
      const response = await getMarkets(typeTV, agencyUf);
      dispatch(LoadMarketSuccess(response, typeTV));
    } catch (error) {
      dispatch(
        LoadMarketError(error instanceof Error ? error.message : '', typeTV)
      );
      toast.error('Ops, houve um problema ao tentar carregar as praças.');
    }
  };

export default LoadMarketRequest;
