import { PointTooltipProps, ResponsiveLine } from '@nivo/line';
import { LineChartData } from './types';
import MetricCard from '../MetricCard';

interface Props {
  data: LineChartData[];
  header: string;
  formatter?: (value: any) => string;
}
const customColors = [
  '#1EC370',
  '#F44DFC',
  '#FDA529',
  '#FC343A',
  '#20B9FC',
  '#5D43FA',
  '#000000'
];

const EncurtedIds: { [key: string]: string } = {
  'TOTAL LI...': 'TOTAL LIGADOS'
};

const customTooltip = ({ point }: PointTooltipProps, formatter?: any) => {
  return (
    <div className="collumn-chart__tooltip">
      <div className="collumn-chart__tooltip__title">
        {EncurtedIds?.[point.serieId] ?? point.serieId}
      </div>
      <div className="collumn-chart__tooltip__text">{`${point.data.x}: ${
        formatter
          ? formatter(Number(point.data.y))?.replace('.', ',')
          : `${point.data.y.toString().replace('.', ',')}%`
      }`}</div>
    </div>
  );
};

export default function LineChart({ data, header, formatter }: Props) {
  const reversedData = [...data].reverse();
  const colors = reversedData.map((e) => e.color);
  return (
    <MetricCard
      classes={{ content: 'fill-height d-flex flex-column align-center' }}
      header={header}
      height="450px"
    >
      <ResponsiveLine
        data={reversedData}
        margin={{ top: 50, right: 110, bottom: 100, left: 60 }}
        xScale={{ type: 'point' }}
        colors={colors || customColors}
        crosshairType="bottom"
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle',
          truncateTickAt: 0
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: -40,
          legendPosition: 'middle',
          truncateTickAt: 0,
          format: (value) => `${formatter ? formatter(value) : value}`
        }}
        tooltip={(point) => customTooltip(point, formatter)}
        pointSize={10}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'color', modifiers: [] }}
        pointLabel="data.yFormatted"
        pointLabelYOffset={-12}
        enableTouchCrosshair
        useMesh
        enableGridX={false}
        theme={{
          axis: {
            ticks: {
              text: {
                fontSize: 14,
                fontFamily: 'inherit',
                fontWeight: 400,
                fill: '#000000'
              }
            }
          },
          legends: {
            text: {
              fontSize: 14,
              fontFamily: 'inherit',
              fontWeight: 400,
              fill: '#000000'
            }
          },
          grid: {
            line: {
              stroke: '#e0e0e0',
              strokeDasharray: '4 4'
            }
          }
        }}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 60,
            itemsSpacing: 10,
            itemDirection: 'left-to-right',
            itemWidth: 100,
            itemHeight: 20,
            itemOpacity: 1,
            symbolSize: 14,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </MetricCard>
  );
}

LineChart.defaultProps = {
  formatter: undefined
};
