import './SwitchToggle.scss';
import React, { useEffect } from 'react';
import { Grid, Switch, Typography } from '@mui/material';
import { useField } from '@unform/core';
import { attributeMaskOrUnmask } from '../../helpers/Utils';

interface Props {
  label: string;
  name?: string;
  disabled?: boolean;
  readOnly?: boolean;
  open?: boolean;
  className?: string;
  onClick?(): void;
}

const SwitchToggle: React.FC<Props> = ({
  open,
  label,
  onClick,
  className,
  name,
  disabled,
  readOnly
}) => {
  const { fieldName, registerField } = useField(name || 'switch');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => {
        return open;
      }
    });
  }, [fieldName, registerField, open]);

  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <Grid className="container">
      <Switch
        checked={open}
        className={`${className} switch-toggle ${
          open && 'switch-toggle__Mui-checked'
        }`}
        disabled={disabled}
        onClick={!readOnly ? handleClick : undefined}
        {...attributeMaskOrUnmask(false)}
      />
      <Typography className={`label ${open ? 'label-checked' : ''}`}>
        {label}
      </Typography>
    </Grid>
  );
};

SwitchToggle.defaultProps = {
  className: undefined,
  disabled: false,
  onClick: undefined,
  open: false,
  readOnly: false,
  name: undefined
};

export default SwitchToggle;
