import { Reducer } from 'redux';
import { GroupsState, GroupAction, GroupsTypes, IGroup } from './Groups.types';
import { getUuid } from '../../../../helpers/UUID';

const groupsInitialState: GroupsState = {
  groups: [
    {
      id: 1,
      name: 'Grupo 1',
      validated: false,
      submited: false,
      uuid: getUuid()
    }
  ]
};

// eslint-disable-next-line react-hooks/rules-of-hooks
// const dispatch = useAppDispatch();

const reducer: Reducer<GroupsState, GroupAction> = (
  state = groupsInitialState,
  action
): GroupsState => {
  switch (action.type) {
    case GroupsTypes.RESET_GROUPS: {
      return groupsInitialState;
    }
    case GroupsTypes.SAVE_GROUPS: {
      if (action.groups?.length) {
        return {
          ...state,
          groups: action.groups
        };
      }
      return {
        ...state,
        groups: [...state.groups]
      };
    }
    case GroupsTypes.SAVE_GROUP_DATA: {
      if (action.payload.id && action.payload.name) {
        const groups = state.groups.filter(
          (group) => group.id !== action.payload.id
        );

        let group = state.groups.find((g) => g.id === action.payload.id);

        group = {
          ...group,
          data: action.payload.data,
          error: false,
          erros: undefined,
          validated: action.payload.validated,
          submited: action.payload.submited,
          name: action.payload.name,
          id: action.payload.id,
          uuid: action.payload.uuid,
          changed: action.payload.changed
        };
        return {
          ...state,
          groups: [...groups, group].sort((a, b) => a.id - b.id)
        };
      }
      return {
        ...state,
        groups: [...state.groups]
      };
    }
    case GroupsTypes.ADD_GROUP: {
      const groups = [...state.groups];
      const lastGroup: IGroup | undefined = groups[groups.length - 1];
      const nId = lastGroup ? lastGroup.id + 1 : 1;

      const group = {
        name: `Grupo ${groups.length + 1}`,
        id: nId,
        uuid: getUuid(),
        validated: false,
        submited: false
      };

      groups.push(group);

      return {
        ...state,
        groups
      };
    }
    case GroupsTypes.CLONE_GROUP: {
      const groups = [...state.groups];
      const nId = groups[groups.length - 1]
        ? groups[groups.length - 1].id + 1
        : 1;
      const originGroup: IGroup | undefined = state.groups.find(
        (g) => g.id === action.payload.id
      );

      const group: IGroup = {
        data: {
          ...originGroup?.data,
          filter: originGroup?.data?.filter
            ? { ...originGroup.data.filter }
            : {},
          availableImpressions: undefined
        },
        error: false,
        erros: undefined,
        name: `${originGroup?.name} cópia`,
        id: nId,
        uuid: getUuid()
      };

      groups.push(group);

      return {
        ...state,
        groups
      };
    }
    case GroupsTypes.SAVE_GROUPS_FORM_DATA_ERROR: {
      const groups = state.groups.filter(
        (group) => group.id !== action.payload.id
      );

      let group = state.groups.find((g) => g.id === action.payload.id);

      group = {
        ...group,
        data: undefined,
        error: true,
        erros: action.payload.erros,
        id: action.payload.id,
        name: action.payload.name,
        uuid: action?.payload?.uuid
      };

      return {
        ...state,
        groups: [...groups, group]
      };
    }
    case GroupsTypes.REMOVE_GROUPS: {
      const groups = state.groups.filter(
        (group) => group.id !== action.payload.id
      );

      return {
        ...state,
        groups
      };
    }
    case GroupsTypes.REMOVE_GROUPS_FORM_ERROR: {
      const groups = state.groups.filter(
        (group) => group.id !== action.payload.id
      );

      let group = state.groups.find((g) => g.id === action.payload.id);

      group = {
        ...group,
        error: false,
        erros: undefined,
        name: action.payload.name,
        id: action.payload.id,
        uuid: action?.payload?.uuid
      };

      return {
        ...state,
        groups: [...groups, group]
      };
    }
    default:
      return state;
  }
};

export default reducer;
