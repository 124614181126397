import React, { useCallback, useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import { getUuid } from '../../helpers/UUID';
import { formatterNumber } from '../../helpers/Formatters';
import usePlanningRedux from '../Planning/usePlanningRedux';
import { PlanningRow } from '../../store/modules/Planning/Planning.types';
import { TV_ABERTA_GLOBO } from '../../helpers/Utils';
import useFilters from '../Filters/useFilters';
import { TypeTV } from '../../services/shared/Api.service';
import './AudienceSourceFooter.scss';

interface SourceFooterProps {
  source?: string;
  displayUniverse?: boolean;
}

interface BroadcastInfo {
  id: string;
  channel: string;
  viewer: string | number;
  source: string;
}

interface UniverseProps {
  channel: string;
  viewer: string | number;
  source: string;
}

const AudienceSourceFooter: React.FC<SourceFooterProps> = ({
  source,
  displayUniverse
}) => {
  const { getCurrentFilters } = useFilters();
  const currentFilter = getCurrentFilters();
  const planning = usePlanningRedux().currentPlanning;
  const typeTV = currentFilter?.data?.typeTV;
  const getMetricsPerTarget = useCallback(
    (program: PlanningRow | undefined) => {
      return program?.metricsPerTarget.find((metric) =>
        planning.target
          ? metric.target === planning.target
          : currentFilter?.data?.targets.includes(metric.target)
      );
    },
    [currentFilter?.data?.targets, planning.target]
  );

  const numOfViewersMessage = useMemo(() => {
    const messageOfViewers: BroadcastInfo[] = [];

    if (typeTV === TypeTV.CLOSED) {
      return [
        ...messageOfViewers,
        {
          id: getUuid(),
          channel: 'Telespectadores',
          viewer: formatterNumber(
            getMetricsPerTarget(
              planning.planningPrograms.find(
                (c) => c.channel !== TV_ABERTA_GLOBO
              )
            )?.univTarget || 0,
            { fractionDigits: 0 }
          ),
          source: `projetado baseado no EDTV ${new Date().getFullYear()}`
        }
      ];
    }
    if (typeTV === TypeTV.OPEN) {
      const channels = uniqBy(planning.planningPrograms, 'channel');
      return channels.map((item) => ({
        id: getUuid(),
        channel: item.channel,
        viewer: formatterNumber(
          getMetricsPerTarget(
            planning.planningPrograms.find((c) => c.channel === item.channel)
          )?.univTarget || 0,
          { fractionDigits: 0 }
        ),
        source: `projetado baseado no EDTV ${new Date().getFullYear()}`
      }));
    }
    // later add digital under here
    return undefined;
  }, [typeTV, getMetricsPerTarget, planning.planningPrograms]);

  const getMarketType = () => {
    const market = currentFilter?.data?.market;
    if (!market?.regularPlaza) {
      if (market?.signal === 'NAC') {
        return 'utilizando dados de audiência do Nacional.';
      }
      return `utilizando dados de audiência de ${market?.name}.`;
    }

    return `para a exibidora ${market?.name}, utilizando dados de audiência de ${market?.nm_regularPlaza} como referência.`;
  };

  const sourcePhrase =
    source ||
    `Kantar IBOPE Media, projeção interna calculada a partir do EDTV ${new Date().getFullYear()}, ${getMarketType()}`;

  const setUniverseMessage = (universes: UniverseProps[]) => {
    if (universes.length) {
      if (universes.length > 1) {
        return universes
          .map((uni) => {
            return `${uni.channel} ${uni.viewer}`;
          })
          .join(', ')
          .concat(', ', universes[0].source);
      }
      return `${universes[0].channel} ${universes[0].viewer}`.concat(
        ', ',
        universes[0].source
      );
    }
    return '';
  };

  if (!sourcePhrase && !numOfViewersMessage?.length) {
    return null;
  }
  return (
    <div className="info-box">
      {sourcePhrase ? (
        <p>
          <strong>Fonte:</strong> {sourcePhrase}
        </p>
      ) : null}
      {displayUniverse && numOfViewersMessage?.length ? (
        <p>
          <strong>Universo: </strong>
          {setUniverseMessage(numOfViewersMessage)}
        </p>
      ) : null}
      {typeTV !== TypeTV.CLOSED ? (
        <p className="note">
          * Os programas sinalizados na grade, oferecem uma audiência estimada
          com base na Faixa Horária de referência.
        </p>
      ) : null}
    </div>
  );
};

AudienceSourceFooter.defaultProps = {
  source: '',
  displayUniverse: false
};

export default AudienceSourceFooter;
